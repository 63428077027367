<template>
	<div class="content-box">
		<div class="seach-box">
			<ul class="nav-tabs">
				<li @click="cheackedType(item)" :class="{ active: item.value == sendData.type }" v-for="(item, index) in typeList" :key="index">
					<span>{{ item.name }}</span>
				</li>
				<div class="right">
					<el-dropdown @command="handleCommand">
						<el-button>
							{{ caseText }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="type_accident">事故车辆</el-dropdown-item>
							<el-dropdown-item command="type_case">涉案车辆</el-dropdown-item>
							<el-dropdown-item command="type_illegal">违法车辆</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</ul>
			<div class="form-box">
				<el-form :model="formData" ref="form" label-width="100px" size="mini">
					<el-row type="flex" :gutter="10">
						<el-col :span="4">
							<el-form-item label="车牌号:" prop="car_no"><el-input v-model.trim="formData.car_no" placeholder="请输入车牌号"></el-input></el-form-item>
							<el-form-item label="车架号:" prop="frame_number"><el-input v-model.trim="formData.frame_number" placeholder="请输入车架号"></el-input></el-form-item>
						</el-col>
						<el-col :span="4">
							<!-- <el-form-item label="办案单位:" prop="unit_name">
								<el-input v-model.trim="formData.unit_name" placeholder="请输入办案单位"></el-input>
							</el-form-item> -->
							<el-form-item label="车辆所有人:" prop="agent_name">
								<el-input v-model.trim="formData.car_owner" placeholder="输入“无主”可查看无主车辆"></el-input>
							</el-form-item>
							<el-form-item label="备注信息:" prop="audit_remarks">
								<el-input v-model.trim="formData.audit_remarks" placeholder="请输入备注信息"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="经办人:" prop="agent_name"><el-input v-model.trim="formData.agent_name" placeholder="请输入经办人"></el-input></el-form-item>
							<el-form-item label="入库日期:" prop="storage_time">
								<el-date-picker
									v-model.trim="formData.storage_time"
									type="daterange"
									align="right"
									unlink-panels
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									:picker-options="pickerOptions"
								></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="事故地点:" prop="accident_location">
								<el-input v-model.trim="formData.accident_location" placeholder="请输入事故地点"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="车辆类型:" prop="model">
								<el-select v-model="formData.model" filterable placeholder="请选择车型">
									<el-option  label="全部" value=""></el-option>
									<el-option v-for="item in SelectInfo.model" :key="item.name" :label="item.name" :value="item.name"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="search-btn-box">
						<el-button type="primary" size="mini" @click="loadData">搜索</el-button>

						<el-button type="text" icon="el-icon-remove-outline" @click="clearFormData">重置搜索并刷新</el-button>
					<!-- 	<el-button type="text" icon="el-icon-caret-bottom">更多搜索条件</el-button> -->
					</div>
				</el-form>
			</div>
		</div>

		<!-- //表格 -->
		<el-row type="flex" align="middle" justify="space-between" style="margin-top: 18px" class="edit-btn-box">
			<el-col :span="2"><el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出Excel</el-button></el-col>
		</el-row>
		<div style="height: 20px"></div>
		<el-table border v-loading="loading" :data="tableList" style="width: 100%" :header-cell-style="{ backgroundColor: '#EFEFEF', textAlign: 'center', color: '#333' }">
			<el-table-column fixed prop="record_no" label="流水号" min-width="80" class-name="nopadding">
				<template slot-scope="scope">
					<span>#{{ scope.row.record_no || '无流水号' }}</span>
				</template>
			</el-table-column>
			<el-table-column fixed prop="car_no" label="车牌号" min-width="75" class-name="nopadding"></el-table-column>
			<el-table-column min-width="145" label="车架号/发动机号" class-name="nopadding">
				<template slot-scope="scope">
					<p class="table-number">{{ scope.row.frame_number }}</p>
					<p class="table-number">{{ scope.row.engine_number }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="model" label="车辆类型" min-width="100" class-name="nopadding"></el-table-column>
			<el-table-column prop="brand" label="品牌" min-width="70" class-name="nopadding"></el-table-column>
			<el-table-column prop="color" label="颜色" min-width="65" class-name="nopadding"></el-table-column>
			<el-table-column prop="accident_location" label="事故地点" min-width="200" class-name="nopadding"></el-table-column>
			<el-table-column prop="seizure_date" label="扣押日期" min-width="95" class-name="nopadding"></el-table-column>
			<el-table-column prop="storage_time" label="入库时间" min-width="150" class-name="nopadding"></el-table-column>
			<el-table-column prop="parking_number" label="车位号" min-width="60" class-name="nopadding"></el-table-column>
			<el-table-column prop="delivery_time" label="出场时间" min-width="150" class-name="nopadding"></el-table-column>
			<el-table-column prop="case_status" label="车辆状态" min-width="175" class-name="nopadding"></el-table-column>
			<el-table-column fixed="right" align="center" label="操作" min-width="360" class-name="nopadding">
				<template slot-scope="scope">
					<!-- <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
						移除
					</el-button> -->
					<el-button v-show="scope.row.is_wait_pending" size="mini" type="primary" @click="handlePending(scope.$index, scope.row)">入场审核</el-button>
					<el-button v-show="scope.row.is_wait_review" size="mini" type="primary" @click="handeleReview(scope.row)">入场复核</el-button>
					<el-button v-if="scope.row.is_wait_case_close" size="mini" type="primary" @click="handleOut(scope.$index, scope.row)">结案出场</el-button>
					<!-- 		<el-button v-if="scope.row.is_wait_out_pending" size="mini" type="primary"
						@click="handleOut(scope.$index, scope.row)">出场审核</el-button> -->
					<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)" v-if="!scope.row.is_wait_pending && is_update">编辑</el-button>
					<el-button size="mini" v-if="scope.row.is_print" type="primary" @click="handelePrint(scope.row)">打印结案单</el-button>
					<el-button size="mini" v-if="scope.row.is_show_code" :type="scope.row.print_success ? 'info' : 'primary'" @click="handeleShowQrcode(scope.row)">
						打印二维码
					</el-button>

					<el-button class="margin-left-10" @click="clickDelete(scope.row)" v-if="scope.row.is_operate_del" size="mini" type="danger">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pagination.index"
				background
				layout="total,prev, pager, next,jumper"
				:total="pagination.total"
			></el-pagination>
		</div>
		<AddDialog ref="dialog" :caseType="this.formData.case_type" @confirm="confirm" :showDialog.sync="showDialog"></AddDialog>
		<PendingDialog ref="Pendingdialog" @loadData="loadData" :caseType="this.formData.case_type" @confirm="confirmPending" :showDialog.sync="showPendingDialog"></PendingDialog>
		<WaiteOut ref="WaiteOutdialog" @loadData="loadData" :caseType="this.formData.case_type" :showDialog.sync="showWaiteOut"></WaiteOut>
		<EditDialog ref="EditDialogdialog" @loadData="loadData" :caseType="this.formData.case_type" :showDialog.sync="showEditDialog"></EditDialog>
		<WaitreviewDialog @loadData="loadData" :id="cheakedItem.id" :showDialog.sync="showWaitreview" :caseType="this.formData.case_type"></WaitreviewDialog>
		<QrCode :show.sync="showQrcode" :info="cheakedItem" @success="printSuccess"></QrCode>
		<Print ref="Print"></Print>
	</div>
</template>

<script>
import WaitreviewDialog from './components/wait_reviewDialog.vue';
import AddDialog from './components/addDialog.vue';
import PendingDialog from './components/pendingDialog.vue';
import WaiteOut from './components/waiteOut.vue';
import EditDialog from './components/EditDialog.vue';
import QrCode from '../../components/qrcode.vue';
import Print from '@/components/Print.vue';
import { mapState } from 'vuex';
import { Row } from 'element-ui';
export default {
	components: {
		AddDialog,
		PendingDialog,
		WaiteOut,
		EditDialog,
		WaitreviewDialog,
		QrCode,
		Print
	},
	data() {
		return {
			activeName: 'second',
			showPendingDialog: false, //是否打开审核弹窗
			showDialog: false, //是否打开新增编辑弹出框
			showWaiteOut: false, //结案出场
			showEditDialog: false, //编辑弹出框
			showWaitreview: false, //复核弹窗
			showQrcode: false,
			cheakedItem: {}, //当前选中需要编辑或删除的列表项
			typeList: [
				{
					name: '全部车辆',
					value: ''
				},
				{
					name: '入场待审核',
					value: 'status_wait_pending'
				},
				{
					name: '场内车辆',
					value: 'status_already_stored'
				},
				{
					name: '入场待复核',
					value: 'status_wait_review'
				},

				{
					name: '待出场',
					value: 'status_wait_case_close'
				},
				{
					name: '出场待审核',
					value: 'status_wait_out_pending'
				},
				{
					name: '已出场',
					value: 'status_already_out'
				},
				{
					name: '放空',
					value: 'status_success'
				}
			], //车辆状态，集合
			loading: false, //加载状态

			sendData: {
				type: '',
				time: ''
			}, //选中的时间即车辆状态

			formData: {
				car_no: '',
				frame_number: '',
				unit_name: '',
				audit_remarks: '',
				agent_name: '',
				storage_time: '',
				case_type: 'type_accident',
				record_id: '',
				index: 0,
				car_owner: '',
				accident_location: '',
				model:'',
			}, //搜索条件
			pagination: {
				index: 0,
				total: 0
			}, //翻页数据
			tableList: [], //表格数据
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			}, //快捷选中时间的函数
			multipleSelection: [], //选中的id集合，用于删除
			ExcelData: '',
			is_update: false
		};
	},
	mounted() {
		//初始化加载数据
		if (this.messageInfo.id) {
			if (this.messageInfo.case_status) this.sendData.type = this.messageInfo.case_status;
			if (this.messageInfo.case_type) this.formData.case_type = this.messageInfo.case_type;
			if (this.messageInfo.record_id) this.formData.record_id = this.messageInfo.record_id;
			this.$store.commit('clearMessageInfo');
		}
		if (this.userInfo.user_type == 'user_logistics' || this.userInfo.user_type == 'user_manager') {
			this.typeList.splice(1, 0, {
				name: '待入场',
				value: 'status_wait_stored'
			});
		}
		this.loadData();
	},
	computed: {
		...mapState(['SelectInfo', 'userInfo', 'messageInfo']),
		caseText() {
			//枚举车辆处理
			switch (this.formData.case_type) {
				case 'type_case':
					return '涉案车辆';
				case 'type_accident':
					return '事故车辆';
				case 'type_illegal':
					return '违法车辆';
			}
			return '';
		}
	},
	methods: {
		/**
		 * 初始化请求
		 */
		async loadData() {
			if (this.loading) return;
			//组装请求参数
			this.loading = true;
			let sendData = {
				car_no: this.formData.car_no,
				frame_number: this.formData.frame_number,
				unit_name: this.formData.unit_name,
				agent_name: this.formData.agent_name,
				remarks: this.formData.audit_remarks,
				case_type: this.formData.case_type,
				record_id: this.formData.record_id,
				car_owner: this.formData.car_owner,
				accident_location: this.formData.accident_location,
				model: this.formData.model
			};
			if (this.formData.storage_time?.length == 2) {
				sendData.storage_time_begin = this.formData.storage_time[0];
				sendData.storage_time_end = this.formData.storage_time[1];
			} else {
				sendData.storage_time_begin = '';
				sendData.storage_time_end = '';
			}
			sendData.case_status = this.sendData.type;
			sendData.index = this.formData.index;
			let res = await this.http.apiPost('v1/Core/RecordPage', sendData);
			console.log('aaa',res)
			this.loading = false;
			if (res.flag) {
				let data = res.data;
				this.is_update = data.is_update;
				this.pagination.total = data.pager.total;
				this.tableList = data.pager.rows.map(v => {
					v.print_success = false;
					return v;
				});
				this.ExcelData = data.url;
			} else {
				this.$message(res.msg);
			}

			this.$store.dispatch('getSelectInfo');
		},
		//重置搜索条件
		clearFormData() {
			this.formData.record_id = '';
			this.formData.car_owner = '';
			this.formData.index = 0;
			this.$refs['form'].resetFields();
			this.loadData();
		},
		/**
		 * 弹窗框组件回调，参数为用户填写的数据form{}
		 */
		async confirm(formData) {
			let res = await this.http.apiPost('v1/Core/RecordEdit', formData, true);
			if (res.flag) {
				this.$refs.dialog.clear();
				this.showDialog = false;
				this.$notify({
					title: '温馨提示',
					message: '操作成功',
					type: 'success',
					offset: 150,
					duration: 1500
				});
				this.loadData();
			} else {
				this.$message(res.msg);
			}
		},
		//审核确认
		async confirmPending(formData) {
			var res = await this.http.apiPost('v1/Core/AuditEdit', formData, true);
			if (res.flag) {
				this.$refs.Pendingdialog.clear();
				this.showPendingDialog = false;
				this.$notify({
					title: '温馨提示',
					message: '操作成功',
					type: 'success',
					duration: 1500
				});
				this.loadData();
			} else {
				this.$message.error(res.msg);
			}
		},
		//点击打印按钮
		printSuccess() {
			this.cheakedItem.print_success = true;
		},
		// 打开新增弹出框
		addInfo() {
			this.showDialog = true;
		},
		//当前车辆类型
		handleCommand(command) {
			this.formData.record_id = '';
			this.formData.case_type = command;
			this.loadData();
		},
		//页数变化
		handleCurrentChange(val) {
			this.pagination.index = val;
			this.formData.index = val - 1;
			this.loadData();
		},
		//选中车辆状态
		cheackedType(item) {
			this.formData.index = 0;
			this.sendData.type = item.value;
			this.loadData();
		},
		//审核选中项
		handlePending(index, item) {
			this.showPendingDialog = true;
			this.$refs.Pendingdialog.setFormData(item);
		}, //结案出场
		handleOut(index, item) {
			this.showWaiteOut = true;
			this.$refs.WaiteOutdialog.setFormData(item);
		},
		//编辑选中项
		handleEdit(index, item) {
			// console.log(index,item);
			// this.cheakedItem=item;
			this.showEditDialog = true;
			this.$refs.EditDialogdialog.setFormData(item);
		},
		handeleReview(row) {
			this.showWaitreview = true;
			this.cheakedItem = row;
		},
		async handeleShowQrcode(row) {
			console.log('row',row)
			if(row.code_url==""){
				let res = await this.http.apiGet('v1/Core/GetCode', { id: row.id }, true);
				console.log('111',res)
				this.showQrcode = true;
				row.code_url=res.data.code_url
				this.cheakedItem = row;
				console.log('222',row)
			}else{
				this.showQrcode = true;
				this.cheakedItem = row;
				// console.log('ccc',res)
			}
		},
		handelePrint(row) {
			this.$refs.Print.beganPrint(row.id);
		},
		//表格选中事件
		handleSelectionChange(val) {
			this.multipleSelection = val.map(v => {
				v = v.id;
				return v;
			});
		},
		/**
		 * @param {Object} list id集合
		 * 单个以及多条删除表格列表数据
		 */
		clickDelete(row) {
			//不传参数则为批量删除，取multipleSelection  中的id集合
			// if (!list) list = this.multipleSelection;
			// let res = await this.http.apiPost("v1/Core/RecordDelete", {
			// 	list,
			// });
			// if (res.flag) {
			// 	this.$notify({
			// 		title: "温馨提示",
			// 		message: "删除成功",
			// 		type: "success",
			// 		duration: 1500,
			// 	});
			// 	this.loadData();
			// } else {
			// 	this.$message(res.msg);
			// }
			this.$confirm('此操作将删除该车辆所有记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let res = await this.http.apiGet(
					'v1/Park/DelRecord',
					{
						id: row.id
					},
					true
				);
				if (res.flag) {
					this.loadData();
					this.$notify({
						title: '温馨提示',
						message: '删除成功',
						type: 'success',
						duration: 1500
					});
				} else {
					this.$message.warning(res.msg);
				}
			});
		},
		/**
		 * @param {Object} index table所在下标值
		 * @param {Object} row 当前行数据
		 * 删除本行数据
		 */
		handleDelete(index, row) {
			this.deletTableList([row.id]);
		},
		/**
		 * 表格导出
		 * @param {Object} sendData 请求参数
		 */
		async exportExcel() {
			window.open(this.ExcelData);
		}
	}
};
</script>

<style>
.nopadding {
	padding: 8px 0 !important;
}
</style>
<style scoped lang="scss">
.content-box {
	padding: 10px 30px 0;

	.table-number {
		margin: 0;
		color: #3399ff;
		font-size: 13px;
		text-decoration: underline;
		padding: 0;
	}

	.pagination {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}

	.seach-box {
		border-radius: 5px;
		border: 0.5px solid #cccccc;

		.form-box {
			padding: 17px 29px 13px 29px;
		}
	}
}
</style>
