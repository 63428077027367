<template>
	<div class="dispatch-content">
		<edit-dialog :show.sync="showPop" @comfirm='getList' :info="detailsInfo"></edit-dialog>
		<el-row type="flex" align="middle" justify="space-between">
			<h4 class="common-title">派单列表</h4>
			<div>
				<el-date-picker :clearable="false" @change="changeTime" format="yyyy 年 MM 月 dd 日"
					value-format="yyyy-MM-dd" v-model="dateVal" size="small" type="daterange" align="right"
					unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					:picker-options="pickerOptions">
				</el-date-picker>
				<el-button type="primary" class="margin-left-10" icon="el-icon-download" size="small"
					@click="exportExcel">导出Excel</el-button>
			</div>
		</el-row>
		<el-row class="margin-top-30" :gutter="20">
			<el-col :span="6">
				<el-card shadow="hover">
					<div class="u-flex-col u-col-center">
						<span class="text-bold font-18">查询时间段派单</span>
						<div class="font-22 margin-top-5 u-flex u-row-left">
							<span class="font-26 text-bold text-red">{{pagination.total||0}}</span>
							<span class="margin-left-5 text-grey">单</span>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card shadow="hover">
					<div class="u-flex-col u-col-center">
						<span class="text-bold font-18">今日派单</span>
						<div class="font-22 margin-top-5 u-flex u-row-left">
							<span class="font-26 text-bold text-red">{{statistics.day_count||0}}</span>
							<span class="margin-left-5 text-grey">单</span>
						</div>
					</div>
				</el-card>
			</el-col>

			<el-col :span="6">
				<el-card shadow="hover">
					<div class="u-flex-col u-col-center">
						<span class="text-bold font-18">本月派单</span>
						<div class="font-22 margin-top-5 u-flex u-row-left">
							<span class="font-26 text-bold text-red">{{statistics.month_count||0}}</span>
							<span class="margin-left-5 text-grey">单</span>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card shadow="hover">
					<div class="u-flex-col u-col-center">
						<span class="text-bold font-18">本年派单</span>
						<div class="font-22 margin-top-5 u-flex u-row-left">
							<span class="font-26 text-bold text-red">{{statistics.year_count||0}}</span>
							<span class="margin-left-5 text-grey">单</span>
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<div class="u-flex u-row-between u-col-center margin-top-30">
			<el-button type="primary" icon="el-icon-plus" size="small" @click='navToAdd'>新增派单</el-button>
			<el-form :inline="true" ref="form" :model="sendData" size="mini">
				<el-form-item label="派单结果">
					<el-select @change="getList" v-model="sendData.is_empty" filterable placeholder="按状态选择"
						style="width: 100%;">
						<el-option label="全部" value=""></el-option>
						<el-option label="非放空" :value="false"></el-option>
						<el-option label="放空" :value="true"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="事故类型" prop="accident_type">
					<el-select v-model="sendData.accident_type" @change="getList" filterable placeholder="请选择事故类型"
						style="width: 100%;">
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in SelectInfo.accident_type" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="施救人员" prop="work_uid">
					<el-select v-model="sendData.work_uid" @change="getList" filterable placeholder="请选择施救人员"
						style="width: 100%;">
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in SelectInfo.rescuers" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="接单状态" prop="is_taking">
					<el-select @change="getList" v-model="sendData.is_taking" filterable placeholder="按状态选择"
						style="width: 100%;">
						<el-option label="全部" value=""></el-option>
						<el-option label="已接单" :value="true"></el-option>
						<el-option label="未接单" :value="false"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="search">
					<el-input v-model="sendData.search" suffix-icon="el-input__icon el-icon-search"
						placeholder="事故地点关键字">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="danger" icon="el-icon-circle-close" @click="resetFields" size="mini">重置搜索
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click='search'>查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table stripe border :data='orderList' style="width: 100%"
			:header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}">
			<el-table-column :formatter="formatter" prop="order_no" align="center" label="派单编号" min-width="80">
				<template slot-scope="scope">
					<span >#{{scope.row.order_no||'无编号'}}</span>
				</template>
			</el-table-column>
			<el-table-column :formatter="formatter" prop="address" align="center" label="事故地点" min-width="180">
			</el-table-column>
			<el-table-column :formatter="formatter" prop="phone" align="center" label="民警电话" min-width="110">
			</el-table-column>
			<el-table-column :formatter="formatter" prop="accident_type" align="center" label="事故类型" min-width="100">
			</el-table-column>
			<el-table-column :formatter="formatter" prop="create_time" align="center" label="派单时间" min-width="180">
			</el-table-column>
			<el-table-column :formatter="formatter" prop="work_name" align="center" label="施救人员" min-width="110">
			</el-table-column>
			<el-table-column :formatter="formatter" prop="work_phone" align="center" label="施救人员电话" min-width="110">
			</el-table-column>
			<el-table-column :formatter="formatter" prop="work_phone" align="center" label="派单结果" min-width="110">
				<template slot-scope="scope">
					<span v-if="scope.row.is_empty===null">-</span>
					<span v-else-if="scope.row.is_empty===true">放空</span>
					<span v-else>非放空</span>
				</template>
			</el-table-column>
			<el-table-column :formatter="formatter" prop="is_time" align="center" label="接单时间" min-width="180">
			</el-table-column>
			<el-table-column label="操作" align="center" fixed="right" min-width="200">
				<template slot-scope="scope">
					<el-button type="primary" icon="el-icon-edit-outline" size="mini" @click="showDetails(scope.row)">
						查看详情</el-button>
					<el-button type="danger" v-if="scope.row.is_operate_del" @click='deleteOrder(scope.row)'
						icon="el-icon-delete" size="mini">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="u-flex u-row-right margin-top-30">
			<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background
				layout="total,prev, pager, next,jumper" :total="pagination.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import EditDialog from "./components/editDialog.vue";
	export default {
		name: 'dispatch',
		components: {
			EditDialog
		},
		data() {
			return {
				sendData: {
					accident_type: "",
					work_uid: '',
					search: '',
					is_taking: "",
					size: 10,
					time_start: "",
					time_end: '',
					is_empty: "",
				},
				showPop: false,
				dateVal: '',
				excelUrl: '',
				pagination: {
					index: 1,
					total: 0,
				}, //翻页数据
				orderList: [],
				statistics: {}, //数据统计
				detailsInfo: {},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		computed: {
			...mapState(["SelectInfo"]),
		},
		mounted() {
			// this.getNowDate();
			this.getList();
		},
		methods: {
			formatter(row, column, cellValue, index) {
				if (!cellValue) return "-"
				else return cellValue
			},
			// getNowDate() {
			// 	var date = new Date();
			// 	date.toDateString();
			// 	this.dateVal = [date, date];
			// 	this.sendData.time_start = date;
			// 	this.sendData.time_end = date;
			// },
			// 重置搜索表单
			resetFields() {
				this.sendData.time_start = "";
				this.sendData.time_end = "";
				this.dateVal = "";
				this.$refs['form'].resetFields();
				this.getList();
			},
			search() {
				this.pagination.index = 1;
				this.getList();
			},
			showDetails(row) {
				this.detailsInfo = row
				this.showPop = true;
			},
			//删除订单
			deleteOrder(row) {
				this.$confirm('此操作将删除该记录,并删除该订单所有车辆, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await this.http.apiGet('v1/Park/DelOrder', {
						id: row.id
					}, true);
					if (res.flag) {
						this.getList();
						this.$notify({
							title: "温馨提示",
							message: "删除成功",
							type: "success",
							duration: 1500,
						});
					} else {
						this.$message.warning(res.msg);
					}

				})
			},
			/**
			 * 改变时间
			 */
			changeTime(time) {
				if (!time) return;
				this.sendData.time_start = time[0];
				this.sendData.time_end = time[1];
				this.getList();
			},
			/**
			 * 表格导出
			 * @param {Object} sendData 请求参数
			 */
			async exportExcel() {
				window.open(this.excelUrl);
			},
			/**
			 * 获取分页
			 */
			async getList() {
				this.sendData.index = this.pagination.index - 1;
				let res = await this.http.apiPost('v1/Park/GetOrderList', this.sendData, true);
				if (res.flag) {
					this.pagination.total = res.data.data.total;
					this.orderList = res.data.data.rows;
					this.statistics = res.data.statistics
					this.excelUrl = res.data.url;
				} else {
					this.$message.error(res.msg);
				}
			},
			/**
			 * 翻页处理函数
			 */
			handleCurrentChange(index) {
				this.pagination.index = index;
				this.getList();
			},
			/**
			 * 跳转新增
			 */
			navToAdd() {
				this.$router.push({
					name: 'adddispatch'
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.dispatch-content {
		height: 100%;
		padding: 20px 30px;


	}
</style>
