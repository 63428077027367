<template>
	<div class="container">
		<div class="content">
			<div class="menu">
				<div class="menu-item" v-if="showDispatch" @click="navTo('adddispatch')">
					<img src="../assets/images/home/add.png">
					<span>新增派单</span>
				</div>
				<div class="menu-item" @click="navTo('processing')">
					<img src="../assets/images/home/manage.png">
					<span>车辆管理</span>
				</div>
				<div class="menu-item" @click="navTo('carStatistics')">
					<img src="../assets/images/home/baobiao.png">
					<span>车辆统计</span>
				</div>
				<div class="menu-item" @click="navTo('notice')">
					<img src="../assets/images/home/notice.png">
					<span>公告管理</span>
				</div>
				<div class="menu-item" @click="navToMessage">
					<p class="badge" v-show="Notice.notice_num">{{Notice.notice_num||0}}</p>
					<img src="../assets/images/home/message.png">
					<span>最新提醒</span>
				</div>

			</div>
		</div>
		<div class="user">
			<div class="item bg-my" @click="showUserPop">
				<img src="../assets/images/u17.png">
				<span>我的信息</span>
			</div>
			<div class="item bg-my-query" @click="navTo('remind')">
				<img src="../assets/images/u20.png">
				<span>提醒设置</span>
			</div>
			<div class="item bg-my-personnel" @click="navToAdmin">

				<img src="../assets/images/u25.png">
				<span>人员设置</span>
			</div>
		</div>
		<transition name="el-fade-in-linear">
			<div class="login-dialog" v-if="showLogin">
				<el-card class="dialog-body">
					<div slot="header" class="clearfix">
						<span>用户登录</span>
						<el-button @click="showLoginMask(false)" style="float: right; padding: 3px 0" type="text"
							icon="el-icon-close"></el-button>
					</div>
					<el-form :model="userForm" status-icon :rules="rules" ref="userForm" label-width="80px"
						class="demo-ruleForm">
						<el-form-item label="用户名" prop="username">

							<el-row justify="space-between" type="flex" align="middle">
								<el-col :span="17">
									<el-input @change="changeInput=true" type="text" v-model.trim="userForm.username"
										autocomplete="off"></el-input>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="密　码" prop="password">
							<el-row justify="space-between" type="flex" align="middle">
								<el-col :span="17">
									<el-input @change="changeInput=true" type="password" v-model="userForm.password"
										autocomplete="off"></el-input>
								</el-col>
								<el-col :span="6">
									<el-checkbox v-model="isRemember">记住密码</el-checkbox>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="验　证">
							<div style="user-select: none;">
								<drag-verify ref="dragVerify" handlerBg="#4585EB" :isPassing.sync="sliderDone"
									completedBg="#93a6ff" text="请按住滑块拖动" successText="验证通过"
									handlerIcon="el-icon-arrow-right" successIcon="el-icon-check">
								</drag-verify>
							</div>
						</el-form-item>
						<el-row>
							<el-button style="width: 100%;" type="primary" @click="submitForm('userForm')">登录
							</el-button>
						</el-row>
					</el-form>
				</el-card>
			</div>
		</transition>
	</div>
</template>

<script>
	// 基本滑块验证组件
	import dragVerify from "@/components/dragVerify";
	import {
		mapState,
		mapMutations
	} from "vuex"
	export default {
		components: {
			dragVerify
		},
		data() {
			return {
				userForm: {
					username: "",
					password: "",
				},
				sliderDone: false, //是否滑动滑块
				isRemember: false,
				showLogin: false,
				changeInput: false, //是否改动输入数据
				timer: null,
				rules: {
					username: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						},

					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '密码不能小于6位数',
							trigger: 'change'
						}
					],

				}
			}
		},
		computed: {
			...mapState(["userInfo", "isLogin", "Notice", 'menuList']),
			showDispatch() {
				if (this.menuList?.length==0) return false
				return this.menuList.some(v => v.route_address == 'adddispatch');
			},
		},
		async mounted() {
			// console.log(JSON.parse(this.userInfo));
			this.isRemember = localStorage.getItem("isRemember") == 'true';
			if (this.isRemember) {
				let login_info = localStorage.getItem("login_info")
				if (login_info) this.userForm = JSON.parse(login_info);
			}
			const that = this;
			eventBus.$on('changeLogin', function(val) {
				that.showLoginMask(true);
			})
			// this.getNewNotice();
		},
		methods: {
			...mapMutations(["Login", "Logout", "setRember"]),
			showLoginMask(data) {
				this.showLogin = data;
			},
			navToMessage() {
				this.$router.push({
					name: "message"
				})
			},
			showUserPop() {
				eventBus.$emit('showInfo');
			},
			navTo(path) {
				if (!this.isLogin) {
					this.showLoginMask(true);
					return;
				}
				this.$router.push({
					path: '/supervise/'+path,
				})
			},
			async navToAdmin() {
				let res = await this.http.apiGet("v1/user/ManageLogin", null);
				if (res.data) {
					window.open(res.data.url)
				} else {
					this.$message.warning(res.msg)
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						if (!this.sliderDone) return this.$message({
							message: "请按住滑块，拖动到最右边",
							type: 'warning',
							offset: 300,
							duration: 1500,
						});
						//关闭登录弹窗
						var password = this.http.md5(this.userForm.password);
						// eslint-disable-next-line no-console
						var sendData = {};
						if (localStorage.getItem("login_info") && !this.changeInput) {
							sendData = JSON.parse(localStorage.getItem("login_info"));
						} else {
							sendData = {
								username: this.userForm.username,
								password
							}
						}
						sendData.is_web = true;
						// 如果是记住密码
						if (this.isRemember) {
							localStorage.setItem("isRemember", true);
							localStorage.setItem("login_info", JSON.stringify(sendData));
						} else {
							localStorage.setItem("isRemember", false);
							localStorage.removeItem("login_info");
						}
						let res = await this.http.apiPost("v1/User/login", sendData, true);
						if (res.flag) {
							this.Login(res.data);
							this.showLoginMask(false);
							this.$notify({
								title: '欢迎回来',
								message: res.data.info.name,
								type: 'success',
								offset: 150,
								duration: 1500,
							});
							this.sliderDone = false;
							this.$refs.dragVerify.reset();
							if (!this.isRemember) {
								this.userForm = {
									username: "",
									password: ""
								}
							}
						} else {
							this.sliderDone = false;
							this.$refs.dragVerify.reset();
							this.$message(res.msg);
							this.Logout();
						}
						// console.log(res);

						// this.Login({
						// 	info:JSON.stringify({name:"KKKKKzz",face:""}),
						// 	token:"123123213"
						// });
						//重置表单

					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
		}
	};
</script>

<style lang="scss" scoped>
	.container {
		height: 100%;

		.login-dialog {
			width: 100%;
			background-color: rgba(215, 215, 215, .6);
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 999;
			display: flex;
			justify-content: center;
			align-items: center;

			.dialog-body {
				width: 450px;
			}
		}

		.user {
			width: 656px;
			margin: 40px auto 0;
			display: flex;
			justify-content: center;

			.bg-my {
				background-image: url(../assets/images/u16.png);
			}

			.bg-my-query {
				background-image: url(../assets/images/u19.png);
			}

			.bg-my-personnel {
				background-image: url(../assets/images/u24.png);
			}

			.item {
				width: 171px;
				height: 121px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				margin-right: 50px;

				img {
					width: 64px;
					height: 65px;
				}

				span {
					font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
					font-weight: 700;
					font-style: normal;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

		.content {
			width: 100%;
			height: 430px;
			background-color: #409EFF;
			display: flex;
			justify-content: center;

			.menu {
				display: flex;
				width: 740px;
				justify-content: space-between;
				margin-top: 90px;

				.menu-item {
					height: 132px;
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					cursor: pointer;
					color: #FFFFFF;
					font-size: 18px;
					font-weight: bold;

					.badge {
						width: 18px;
						height: 18px;
						text-align: center;
						line-height: 18px;
						font-size: 12px;
						font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
						font-weight: 700;
						font-style: normal;
						color: #FFFFFF;
						background-color: red;
						position: absolute;
						top: -3px;
						left: 0;
						border-radius: 50%;
					}

					img {
						width: 105px;
						height: 108px;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
</style>
