import Axios from 'axios'
import md5 from "./md5.js";
import {
	Loading
} from 'element-ui';
 const host = "https://parking.gjjttc.com"; //国经正式域名
// const host = "http://192.168.1.109"; //本地域名
// const host = "http://gj.test.cdhzbc.net"; //本地域名
// const host = "/api"; //本地域名
// const host = "http://192.168.1.107:8085"; //开发本地接口地址
const app_key = '22a85f3b-992d-4124-9666-0da2d789e684'; //api密钥
const MSHttp = {
	getTimestamp: function() {
		return Math.round(new Date().getTime() / 1000);
	},
	getNonce: function(length) {
		if (length == null) length = 32;
		var str = "",
			pos = 0,
			arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h',
				'i', 'j', 'k',
				'l',
				'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E',
				'F', 'G', 'H', 'I',
				'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
			];

		for (var i = 0; i < length; i++) {
			pos = Math.round(Math.random() * (arr.length - 1));
			str += arr[pos];
		}
		return str;
	},
	getOrderWithData: function(dic) {
		for (let key in dic) {
			if (typeof(dic[key]) == "undefined") {
				delete dic[key];
			}
		}
		if (dic == null) return '';
		var result = "";
		var sdic = Object.keys(dic).sort(function(a, b) {
			return a.localeCompare(b)
		});
		var value = "";
		for (var ki in sdic) {
			if (dic[sdic[ki]] == null) {
				value = ""
			} else {
				value = dic[sdic[ki]];
				if (typeof value === 'object') value = JSON.stringify(value);
			}
			result += sdic[ki] + value;
		}
		result.replace(/\r/,"");
		result.replace(/\n/,"");
		return result.replace(/\s/g,"");
	},
	getSign: function(queryData, timestamp, nonce) {
		var data = MSHttp.getOrderWithData(queryData);
		return md5.md5(timestamp + nonce + app_key + data);
	},
	getToken() {
		return localStorage.getItem("token");
	},
	setToken(token) {
		localStorage.setItem("token", token);
	},
	clearToken() {
		localStorage.removeItem("token");
	},
	apiUrl(url) {
		return host + "/api/" + url
	},
	url(url) {
		return host + url;
	},
	headers() {
		var data = "";
		let timestamp = MSHttp.getTimestamp(); //时间截
		let nonce = MSHttp.getNonce(); //随机字符串
		let signature = MSHttp.getSign(data, timestamp, nonce); //签名
		let token = MSHttp.getToken();
		// 请求头
		var header = {
			'timestamp': timestamp,
			'nonce': nonce,
			'signature': signature,
			'token': token || "",
		};
		return header
	},

	async apiGet(url, data, showLoading) {
		return await MSHttp.request(MSHttp.apiUrl(url), data, 'GET', 'form', showLoading);

	},
	async apiPost(url, data, showLoading) {
		return await MSHttp.request(MSHttp.apiUrl(url), data, 'POST', null, showLoading);
	},
	request: async function(url, data, method, type, showLoading) {
		let loadingInstance;
		if (showLoading) {
			loadingInstance = Loading.service({
				lock: true,
				text: '加载中..',
				body: true,
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: "loding"
			});
		}
		let timestamp = MSHttp.getTimestamp(); //时间截
		let nonce = MSHttp.getNonce(); //随机字符串
		let signature = MSHttp.getSign(data, timestamp, nonce); //签名
		let token = MSHttp.getToken();
		// 请求头
		var header = {
			'content-type': type == 'form' ? 'application/x-www-form-urlencoded' : 'application/json',
			'timestamp': timestamp,
			'nonce': nonce,
			'signature': signature,
			'token': token || "",
		};
		//Axios配置
		var options = {
			method: method,
			headers: header,
			timeout: 60000,
			url: url,
		}

		if (method == "GET") options.params = data;
		else options.data = data;
		return Axios(options).then(res => {
			if (showLoading) loadingInstance.close()
			if (res.status == 200) {
				return res.data;
			} else {
				return {
					flag: false,
					msg: '网络不给力，请稍后再试.'
				}
			}

		}).catch((err) => {
			if (showLoading) loadingInstance.close()
			return {
				flag: false,
				msg: '网络不给力，请稍后再试.'
			}
		});
	}
}
export default {
	apiGet: MSHttp.apiGet,
	apiPost: MSHttp.apiPost,
	url: MSHttp.url,
	host: host,
	md5: md5.md5,
	headers: MSHttp.headers(),
}
