<template>
	<div class="u-flex-col u-col-center  page">
		<div class="u-flex u-row-center">
			<h1 class="common-title margin-80">填写派单信息</h1>
		</div>
		<div class="form-box">
			<el-form ref="form" :model="form" label-width="auto" :rules="rules">
				
				<el-form-item label="事故地点：" prop="address">
					<el-input v-model.trim="form.address" placeholder="请输入事故地点"></el-input>
				</el-form-item>
				<el-form-item label="事故类型：" prop="accident_type">
					<el-select v-model="form.accident_type" filterable placeholder="请选择事故类型" style="width: 100%;">
						<el-option v-for="item in SelectInfo.accident_type" :key="item.id" :label="item.name"
							:value="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="现场民警电话：" prop="phone">
					<el-input v-model.trim="form.phone" maxlength="11" placeholder="请输入现场民警电话"></el-input>
				</el-form-item>
				<el-form-item label="施救人员：" prop="work_uid">
					<el-select @change="getPhone" v-model="form.work_uid" filterable placeholder="请选择施救人员"
						style="width: 100%;">
						<el-option v-for="item in SelectInfo.rescuers" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="施救人员电话：" prop="work_phone">
					<el-input v-model.trim="form.work_phone" placeholder="请输入施救人员电话" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="备注：" prop="remark">
					<el-input type="textarea" :rows='4' v-model.trim="form.remark"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button style="width: 270px;" type="primary" @click="onSubmit">立即创建</el-button>
					<el-button @click="back">返回</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	export default {
		name: "addDispatch",
		data() {
			var validatePhone = (rule, value, callback) => {
				var isMobile = /^1[3456789]\d{9}$/.test(value);
				if (!isMobile) callback(new Error('请填写正确的手机号码'));
				callback();
			};
			var validatePhone1 = (rule, value, callback) => {
				if (value) {
					var isMobile = /^1[3456789]\d{9}$/.test(value);
					if (!isMobile) callback(new Error('请填写正确的手机号码'));
				}
				callback();
			};
			return {
				form: {
					address: '',
					accident_type: '',
					phone: '',
					work_uid: '',
					work_phone: '',
					remark: '',
				},
				rules: {
					address: [{
						required: true,
						message: '请输入事故地点',
						trigger: 'blur'
					}],
					accident_type: [{
						required: true,
						message: '请选择事故类型',
						trigger: 'blur'
					}],
					work_uid: [{
						required: true,
						message: '请选择施救人员',
						trigger: 'blur'
					}],
					phone: [{
						validator: validatePhone1,
						trigger: 'blur'
					}],
					work_phone: [{
						required: true,
						message: '请输入施救人员电话',
						trigger: 'blur'
					}, {
						validator: validatePhone,
						trigger: 'blur'
					}],
				}
			}
		},
		computed: {
			...mapState(["SelectInfo"]),
		},
		methods: {
			onSubmit() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.addTask();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			/**
			 * 向后台发送请求，新增任务
			 */
			async addTask() {
				let res = await this.http.apiPost('v1/Park/EditOrder', this.form, true);
				if (res.flag) {
					this.$notify({
						title: '温馨提示',
						message: '创建成功',
						type: 'success',
						offset: 150,
						duration: 1500,
					});
					this.$refs['form'].resetFields();
				} else {
					this.$message.error(res.msg);
				}
			},
			getPhone(id) {
				const user = this.SelectInfo.rescuers.find(v => v.id == id);
				if (user) this.form.work_phone = user.phone;
			},
			// 
			back() {
				this.$router.back();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.form-box {
		max-width: 500px;
	}
</style>
