<template>
	<div class="u-flex-col padding-30" style="position: relative;">
		<h3 class="u-text-center margin-30">车辆统计报表</h3>

		<el-row type="flex" justify="end" style="margin-bottom: 10px">
			<!-- <el-select v-model="is_out" @change="loadData" size="small">
				 <el-option :value="false" label="入场时间"></el-option>
				 <el-option :value="true" label="出场时间"></el-option>
			</el-select> -->
			<el-date-picker class="margin-left-10 " v-model="time" size="small" type="daterange" :clearable="false"
				align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" e
				format="yyyy 年 MM 月 dd 日" :picker-options="pickerOptions" value-format="yyyy-MM-dd" @change="loadData">
			</el-date-picker>
			<el-button type="primary" class="margin-left-20 " size="small" @click="outerExcel">导出Excel</el-button>
			<el-button type="primary" class="margin-left-10 " size="small" @click="navtoEmpty">放空统计
			</el-button>
		</el-row>

		<el-table v-if="models.length" :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}"
			:data="tableList" max-height="700" :span-method="arraySpanMethod" border
			:row-class-name="tableRowClassName">
			style="width: 100%" >
			<el-table-column prop="case_type" min-width="80" label="车辆类型"> </el-table-column>
			<el-table-column v-for="(item,index) in models" :prop="'model_'+item.id" :key="item.id" min-width="70"
				align="center" :label="item.name">
				<template slot-scope="scope">
					<p class="table-number" v-if="scope.row['model_'+item.id]!='0'"
						@click="showCarPopup(item.id,scope.row)">{{ scope.row['model_'+item.id] }}
					</p>
					<p v-else>-</p>
				</template>
			</el-table-column>
			<el-table-column prop="sum" min-width="80" align="center" bold label="小计">
				<template slot-scope="scope">
					<p class="table-number"
						@click="showCarPopup('',scope.row)">{{ scope.row.sum }}
					</p>
				</template>
			</el-table-column>
		</el-table>
		<car-list :show.sync="showCarPop" :editSendData='editSendData'></car-list>
	</div>
</template>

<script>
	import carList from '../components/carList.vue'
	export default {
		components: {
			carList
		},
		data() {
			return {
				time: '',
				models: [],
				showCarPop: false,
				tableList: [],
				is_out: false,
				exporturl: '',
				editSendData: {
					case_type: '',
					is_out: false,
					model: 0,
					time_begin: '',
					time_end: "",
				},
				pickerOptions: {
					disabledDate: function(val) {
						return val.getTime() > Date.now() + 3600 * 1000 * 24;
					},
					shortcuts: [{
							text: "最近一周",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近三个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一年",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
								picker.$emit("pick", [start, end]);
							},
						},
					],

				},
			}
		},
		mounted() {
			//初始化月份
			var day = new Date(Date.now()).getDate();
			var month = new Date().getMonth() + 1;
			var year = new Date().getFullYear();
			const begin_time = year + "-" + month + "-" + "1";
			const end_time = year + "-" + month + "-" + day;
			this.time = [begin_time, end_time];
			this.loadData();
		},
		methods: {
			async loadData() {
				const sendData = {
					time_begin: '',
					time_end: '',
					is_out: this.is_out
				};
				if (this.time) {
					sendData.time_begin = this.time[0];
					sendData.time_end = this.time[1];
				}
				let res = await this.http.apiPost('v1/core/CarRecordStatistics', sendData, true);
				if (res.flag) {
					this.models = res.data.models;
					this.tableList = res.data.lists;
					this.exporturl = res.data.exporturl
				} else {
					this.$message.warning(res.msg);
				}
			},
			showCarPopup(id, row) {
				this.editSendData.time_begin = this.time[0];
				this.editSendData.time_end = this.time[1];
				this.editSendData.is_out = row.is_out;
				this.editSendData.case_type = row.case_type_code;
				this.editSendData.model = id;
				this.showCarPop = true;
			},
			outerExcel() {
				window.open(this.exporturl);
			},
			navtoEmpty() {
				this.$router.push({
					name: 'carEmpty'
				})
			},
			//合并表格
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.is_class) {
					return "warning-row";
				}
				return "";
			},
			arraySpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				//这一行是标题
				if (row.is_class) {
					//如果是第一列，合并单元航
					if (columnIndex == 0) {
						return [1, 12];
					} else if (columnIndex < 11) {
						//如果是其余列 不需要展示
						return [0, 0];
					} else {
						//其余正常展示
						return [1, 1];
					}
				} else {
					//不是标题其余正常展示
					return [1, 1];
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.table-number {
		margin: 0;
		color: #3399ff;
		font-size: 14px;
		text-decoration: underline;
		padding: 0;
		cursor: pointer;
	}

	.parking-btn {
		position: absolute;
		top: 50px;
		right: 40px;
	}
</style>
