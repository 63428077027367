<template>
	<div>
		<el-dialog title="批量新增公告车辆" :visible.sync="show" @closed="close" append-to-body width="990px">
			<div class="top">
				<ul class="nav-tabs">
					<li @click="cheackedType(item)" :class="{ active: item.value == sendData.value }" v-for="(item) in typeList" :key="item.name">
						<span>{{ item.name }}</span>
					</li>
				</ul>
				<div class="form-box">
					<el-form ref="form" :model="formData" label-width="100px" size="mini">
						<el-row type="flex" :gutter="10">
							<el-col :span="8">
								<el-form-item label="车牌号:" prop="car_no">
									<el-input v-model="formData.car_no" placeholder="请输入车牌号"></el-input>
								</el-form-item>
								<el-form-item label="车架号:" prop="frame_number">
									<el-input v-model="formData.frame_number" placeholder="请输入车架号"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="车辆类型:" prop="case_type">
									<el-select placeholder="请选择车辆类型" v-model="formData.case_type">
										<el-option v-for="item in SelectInfo.case_type" :key="item.key" :label="item.name" :value="item.key"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="备注信息:" prop="remarks">
									<el-input v-model="formData.remarks" placeholder="请输入备注信息"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="停放天数:" prop="agent_uid">
									<el-row type="flex" align="middle" justify="space-between">
										<el-input type="text" maxlength="3" v-model="formData.park_days_begin"></el-input>
										<span style="margin: 0 6px">—</span>
										<el-input type="text" maxlength="3" v-model="formData.park_days_end"></el-input>
										<span style="margin: 0 3px">天</span>
									</el-row>
								</el-form-item>
								<el-form-item label="超时天数:" prop="storage_time">
									<el-row type="flex" align="middle" justify="space-between">
										<el-input type="text" maxlength="3" v-model="formData.time_out_begin"></el-input>
										<span style="margin: 0 6px">—</span>
										<el-input type="text" maxlength="3" v-model="formData.time_out_end"></el-input>
										<span style="margin: 0 3px">天</span>
									</el-row>
								</el-form-item>
							</el-col>
					
						</el-row>
						<el-row>
							<el-col :span="9">
								<el-form-item label="车辆所有人:" prop="agent_name">
									<el-input v-model="formData.car_owner" placeholder="	输入“无主”可查看无主车辆"></el-input>
							
								</el-form-item>
							
							</el-col>
						</el-row>
						<div class="search-btn-box">
							<el-button type="primary" size="mini" style="width: 100px" @click="getList">搜索</el-button>

							<el-button type="text" icon="el-icon-remove-outline" @click="clearFormData">重置搜索条件</el-button>
							<el-button type="text" icon="el-icon-caret-bottom">更多搜索条件</el-button>
							<!-- <i class=""></i> -->
						</div>
					</el-form>
				</div>
			</div>
			<div style="height:20px"></div>
			<el-table :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}" border v-loading="loading" id="exportTab" :data="tableList" style="width: 100%" max-height="300"
			 @selection-change="handleSelectionChange">>
				<el-table-column type="selection" class-name="nopadding">
				</el-table-column>
				<el-table-column fixed prop="car_no" label="车牌号" class-name="nopadding">
					<template slot-scope="scope">
						<p class="table-number">{{ scope.row.car_no }}</p>
					</template>
				</el-table-column>
				<el-table-column label="车架号/发动机号" width="150" class-name="nopadding">
					<template slot-scope="scope">
						<p class="table-number">{{ scope.row.frame_number }}</p>
						<p class="table-number">{{ scope.row.engine_number }}</p>
					</template>
				</el-table-column>
				<el-table-column prop="brand" label="品牌" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="color" label="颜色" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="closed_case_time" width="200" label="出场审核时间" class-name="nopadding">
				</el-table-column>
				<el-table-column label="超时时长" class-name="nopadding">
					<template slot-scope="scope">
						<span>{{ scope.row.timeout_day }}天{{
                scope.row.timeout_hour
              }}小时</span>
					</template>
				</el-table-column>
			</el-table>
			<el-row type="flex" align="middle" justify="end">
				<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background :page-size="999"
				 layout="total,prev, pager, next,jumper" :total="pagination.total">
				</el-pagination>
			</el-row>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirm">加入公告</el-button>
				<el-button type="primary" @click="close">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog title="公告批次信息" :visible.sync="dialogFormVisible" width="350px" top="30vh">
			<el-row align="middle" type="flex" justify="center">
				<el-col :span="20">
					<el-form ref="sendForm" :model="form" label-width="80px" size="mini" :rules="rules">
						<el-form-item prop="batch_no" label="批次号">
							<el-input v-model="form.batch_no" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item prop="begin_time" label="公告开始">
							<el-date-picker style="width: 100%" value-format="yyyy-MM-dd" v-model="form.begin_time" type="date" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item prop="end_time" label="公告结束">
							<el-date-picker style="width: 100%" value-format="yyyy-MM-dd" v-model="form.end_time" type="date" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="form.remarks" autocomplete="off"></el-input>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer">
				<span class="cheacked_lable">共选了{{ multipleSelection.length }}辆车</span>
				<el-button size="medium" type="primary" @click="confirmAdd">确 定</el-button>
				<el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 导出车辆信息提醒 -->
		<el-dialog title="导出车辆信息提醒" :visible.sync="dialogOutVisible" width="300px" top="30vh">
			<span>本公告批次一共{{ carNum }}辆车，是否立即导出车辆信息</span>
			<div slot="footer">
				<el-row align="middle" type="flex" justify="end">
						<el-button size="medium" type="primary" @click="gerBulletinHandlingCarExport">是</el-button>
					<el-button style="margin-left:5px" size="medium" @click="close">否</el-button>
				</el-row>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	var emptyForm = {
		car_no: "",
		case_type: "",
		frame_number: "",
		remarks: "",
		park_days_begin: "",
		park_days_end: "",
		time_out_begin: "",
		time_out_end: "",
		time_out_month: "",
		car_owner:""
	};
	import {
		mapState
	} from "vuex";
	export default {
		name: "addDialog",
		props: {
			showDialog: {
				type: Boolean,
				default: false,
			},
			caseType: {
				type: String,
				default: "type_case",
			},
		},
		data() {
			return {
				dialogFormVisible: false,
				dialogOutVisible: false,
				title: "",
				show: false,
				formLabelWidth: "120px",
				isconfirm: false, //是否为请求接口
				sendData: {
					value: "",
				},
				form: {
					batch_no: "",
					begin_time: "",
					end_time: "",
					remarks: "",
					
				},
				carNum: 0,
				rules: {
					batch_no: [{
						required: true,
						message: "请输入批次号",
						trigger: "blur",
					}, ],
					begin_time: [{
						required: true,
						message: "请选择开始时间",
						trigger: "change",
					}, ],
					end_time: [{
						required: true,
						message: "请选择结束时间",
						trigger: "change",
					}, ],
				},
				formData: {},
				tableList: [],
				multipleSelection: [],
				loading: false,
				pagination: {
					index: 0,
					total: 0,
				}, //翻页数据
				typeList: [{
						name: "全部超时车辆",
						value: "",
					},
					{
						name: "三个月",
						value: 3,
					},
					{
						name: "六个月",
						value: 6,
					},
				],
				ExcelData: "",
			};
		},
		computed: {
			...mapState(["SelectInfo"]),
		},
		created() {
			this.formData = JSON.parse(JSON.stringify(emptyForm))
		},
		watch: {
			// formData: function(val) {
			// //	console.log(val);
			// 	if (val.id) {
			// 		this.form = JSON.parse(JSON.stringify(val));
			// 	}
			// },
			showDialog: function(val) {
				this.show = val;
				if(val)this.$store.dispatch("getSelectInfo");
			},
		},
		methods: {
			close() {
				this.$emit("update:showDialog", false);
				this.dialogFormVisible = false;
				this.dialogOutVisible = false;
				if (this.$refs.sendForm) this.$refs.sendForm.clearValidate();
				this.$refs.form.clearValidate();
			},
			//重置表单
			clear() {
				console.log("clear");

			},
			 gerBulletinHandlingCarExport(batch_id) {
				window.open(this.ExcelData);
				this.close();
			},
			/**
			 * 导出下载
			 */
			confirmOut() {},
			confirmAdd() {
				this.$refs["sendForm"].validate(async (valid) => {
					if (valid) {
						this.form.recordId = this.multipleSelection;
						let res = await this.http.apiPost(
							"v1/Core/BulletinBatchAdd",
							this.form,
							true
						);
						if (res.flag) {
							this.dialogFormVisible = false;
							this.ExcelData=res.data.url;
							this.carNum = res.data.car_num;
							this.dialogOutVisible=true;
							this.$emit("loadData");
						} else {
							this.$message(res.msg);
						}
					} else {
						return false;
					}
				});
			},
			/**
			 * 获取表格数据
			 */
			async getList() {
				this.loading = true;
				this.formData.index = this.pagination.index;
				this.formData.time_out_month = this.sendData.value;
				let res = await this.http.apiPost(
					"v1/Core/BulletinBatchAddPage",
					this.formData
				);
				this.loading = false;
				if (res.flag) {
					this.pagination.total = res.data.total;
					this.tableList = res.data.rows;
				}
			},
			//页数变化
			handleCurrentChange(val) {
				this.pagination.index = val - 1;
				this.getList();
			},
			//重置搜索条件
			clearFormData() {
				this.formData = JSON.parse(JSON.stringify(emptyForm))
				console.log(emptyForm);
				this.$refs["form"].resetFields();
			},
			cheackedType(item) {
				this.sendData = item;
				this.getList();
			},
			//表格选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val.map((v) => {
					v = v.id;
					return v;
				});
			},
			confirm() {
				if (this.multipleSelection.length == 0) {
					this.$message({
						showClose: true,
						message: "请选择要加入公告的车辆",
						type: "warning",
						offset: 100,
					});
					return;
				}
				this.dialogFormVisible = true;
			},
		},
	};
</script>

<style scoped lang="scss">
	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.form-box {
		padding: 17px 29px 13px 29px;
	}

	.cheacked_lable {
		float: left;
		line-height: 36px;
		font-size: 14px;
	}

	.table-number {
		margin: 0;
		color: #3399ff;
		font-size: 13px;
		text-decoration: underline;
		padding: 0;
	}

	.top {
		width: 100%;
		border-radius: 10px;
		border: 0.5px solid #cccccc;
	
	}
</style>
