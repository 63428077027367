<template>
	<div class="content-box">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<el-row type="flex" align="middle" justify="space-between">
					<span>车辆状态提醒设置</span>
					<div class="right" v-if="Info.user_type=='user_manager'">
						<el-dropdown @command="handleCommand">
							<el-button>
								{{caseText}}<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="type_case">涉案车辆</el-dropdown-item>
								<el-dropdown-item command="type_illegal">违法车辆</el-dropdown-item>
								<el-dropdown-item command="type_accident">事故车辆</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-row>
			</div>
			<el-form ref="form" :model="fromData" label-width="auto" size="medium">
				<el-form-item label="车辆入库待审核时" prop="notice_pending">
					<el-switch v-model="fromData.notice_pending.is_enabled"></el-switch>
					<span class="tips">{{fromData.notice_pending?"已开启":"未开启提醒"}}</span>

				</el-form-item>
				<el-form-item label="车辆出库确认时" prop="notice_confirming">
					<el-switch v-model="fromData.notice_confirming.is_enabled"></el-switch>
					<span class="tips">{{fromData.notice_confirming?"已开启":"未开启提醒"}}</span>
				</el-form-item>
				<el-form-item prop="notice_parking" label="场内停放期间" size="small">
					<el-switch @change="changeSwith($event,fromData.notice_parking.notice_time)" v-model="fromData.notice_parking.is_enabled"></el-switch>
					<span class="tips" v-if="!fromData.notice_parking.is_enabled">可设置提醒时间</span>
					<template v-if="fromData.notice_parking.is_enabled">
						<el-row type="flex" :gutter="10" v-for="(item,index) in fromData.notice_parking.notice_time" :key="index">
							<el-col :span="3">
								<el-input v-model="item.notice_duration"></el-input>
							</el-col>
							<el-col :span="2">
								<el-select v-model="item.unit" placeholder="">

									<el-option label="天" value="天"></el-option>
									<el-option label="小时" value="小时"></el-option>
									<el-option label="分钟" value="分钟"></el-option>
								</el-select>
							</el-col>
							<el-col :span="1">
								<el-button type="primary" icon="el-icon-delete" @click="remove(index,'notice_parking',item.id)" size="mini">删除</el-button>
							</el-col>
						</el-row>
						<el-button type="text" @click="addItem(fromData.notice_parking.notice_time)">新增一条</el-button>
					</template>
				</el-form-item>

				<el-form-item prop="notice_time_out" label="出场超时期间" size="small">
					<el-switch @change="changeSwith($event,fromData.notice_parking.notice_time)" v-model="fromData.notice_time_out.is_enabled"></el-switch>
					<span class="tips" v-if="!fromData.notice_time_out.is_enabled">可设置提醒时间</span>
					<template v-if="fromData.notice_time_out.is_enabled">
						<el-row type="flex" :gutter="10" v-for="(item,index) in fromData.notice_time_out.notice_time" :key="index">
							<el-col :span="3">
								<el-input v-model="item.notice_duration"></el-input>
							</el-col>
							<el-col :span="2">
								<el-select v-model="item.unit" placeholder="">
									<el-option label="天" value="天"></el-option>
									<el-option label="小时" value="小时"></el-option>
									<el-option label="分钟" value="分钟"></el-option>
								</el-select>
							</el-col>
							<el-col :span="1">
								<el-button type="primary" icon="el-icon-delete" @click="remove(index,'notice_time_out',item.id)" size="mini">删除</el-button>
							</el-col>
						</el-row>
						<el-button type="text" @click="addItem(fromData.notice_time_out.notice_time)">新增一条</el-button>
					</template>

				</el-form-item>
				<el-form-item prop="notice_bulletin_expired" label="公告到期时">
					<el-switch v-model="fromData.notice_bulletin_expired.is_enabled"></el-switch>
					<span class="tips">{{fromData.notice_bulletin_expired.is_enabled?"已开启":"未开启提醒"}}</span>
				</el-form-item>
				<el-form-item  label="车辆待入库时">
					<el-switch v-model="istrue"></el-switch>
					<span class="tips">{{istrue?"已开启":"未开启提醒"}}</span>
				</el-form-item>
				<el-row type="flex">
					<el-button style="width: 100px;" type="primary" @click="updateConfig" size="medium">保存</el-button>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	export default {
		data() {
			return {
				fromData: {
					"notice_bulletin_expired": {},
					"notice_time_out": {},
					"notice_pending": {},
					"notice_confirming": {},
					"notice_parking": {},
				},
				istrue:false,
				// caseText: "涉案车辆",
				caseType: "",
			};
		},
		mounted() {
			if (this.Info.user_type == "user_manager") {
				this.caseType = "type_case";
			}
			this.loadData()
		},
		computed: {
			...mapGetters({
				Info: "Info",
			}),
			caseText() {
				//枚举车辆处理
				switch (this.caseType) {
					case "type_case":
						return "涉案车辆";
						break
					case "type_accident":
						return "事故车辆";
						break
					case "type_illegal":
						return "违法车辆";
						break
				}
			},
		},
		methods: {
			async loadData() {
				var res = await this.http.apiGet("v1/Config/NoticeConfigPage", {
					case_type: this.caseType
				}, true);
				this.senData = res
				res.data.notice_config.forEach(v => {
					this.fromData[v.notice_type] = {
						is_enabled: v.is_enabled,
						notice_time: v.notice_time,
						notice_type: v.notice_type
					};
				})
			},
			addItem(item) {
				item.push({
					id: 0,
					notice_duration: 1,
					unit: "天"
				})
			},
			async remove(index, key, id) {
				var list = this.fromData[key].notice_time;
				if (list.length == 1) {
					this.$message({
						message: '请至少保留一项配置哦~！',
						type: 'warning'
					});
					return
				}
				var res = await this.http.apiPost("v1/Config/NoticeTimeDelete?id=" + id, null, true);
				if (res.flag) {
					list = list.filter((item, inded) => index != inded)
				} else {
					this.$message(res.msg);
				}
				this.$set(this.fromData[key], "notice_time", list)
			},
			changeSwith(val, item) {
				if (val && item.length == 0) {
					this.addItem(item);
				}
			},
			handleCommand(val) {
				this.caseType = val;
				this.loadData();
			},
			async updateConfig() {
				var config = [],
					time = [];
				for (let key in this.fromData) {
					var item = this.fromData[key];
					config.push({
						notice_type: item.notice_type,
						is_enabled: item.is_enabled
					})
					if (item.notice_time) {
						if (item.notice_time.length > 0) {
							item.notice_time.forEach(v => {
								time.push({
									notice_type: item.notice_type,
									notice_duration: v.notice_duration,
									unit: v.unit,
									id: v.id
								})
							})
						}
					}

				}

				var res = await this.http.apiPost("v1/Config/NoticeConfigEdit", {
					config,
					time,
					case_type: this.caseType
				}, true);
				if (res.flag) {
					this.$notify({
						title: '温馨提示',
						message: '保存成功',
						type: 'success',
						duration: 1500,
					});
				} else {
					this.$message(res.msg);
				}
			}
		},
	}
</script>

<style>
	.el-card__header {
		padding: 10px 20px !important;
	}
</style>
<style scoped lang="scss">
	.content-box {
		padding: 15px 30px 0;
		height: 100%;

		.tips {
			margin-left: 10px;
			font-weight: bold;
		}

		.item {
			width: 710px;
			height: 200px;
			margin: 0 auto;
			border: 0.5px solid #797979;
		}
	}
</style>
