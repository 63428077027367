<template>
	<div class="content-box">
		<div class="seach-box">
			<ul class="nav-tabs">
				<li @click="cheackedType(item)" :class="{ active: item.value == sendData.type }"
					v-for="item in typeList" :key="item.name">
					<span>{{ item.name }}</span>
				</li>
			</ul>
			<div class="form-box">
				<el-form :model="formData" ref="form" label-width="100px" size="mini">
					<el-row type="flex" :gutter="10">
						<el-col :span="4">
							<el-form-item label="车牌号:" prop="car_no">
								<el-input v-model.trim="formData.car_no" placeholder="请输入车牌号"></el-input>
							</el-form-item>
							<el-form-item label="车架号:" prop="frame_number">
								<el-input v-model.trim="formData.frame_number" placeholder="请输入车架号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="办案单位:" prop="unit_name">
								<el-input v-model.trim="formData.unit_name" placeholder="请输入办案单位"></el-input>
							</el-form-item>
							<el-form-item label="备注信息:" prop="remarks">
								<el-input v-model.trim="formData.remarks" placeholder="请输入备注信息"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="经办人:" prop="agent_name">
								<el-input v-model.trim="formData.agent_name" placeholder="请输入经办人"></el-input>
							</el-form-item>
							<el-form-item label="入库日期:" prop="storage_time">
								<el-date-picker value-format="yyyy-MM-dd" v-model.trim="formData.storage_time"
									type="daterange" align="right" unlink-panels range-separator="至"
									start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="公告批次号:" prop="batch_no">
								<el-input v-model="formData.batch_no" placeholder="请输入公告批次号"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<div>
						<el-button type="primary" size="mini" style="width: 100px" @click="loadData">搜索</el-button>
						<el-button type="text" icon="el-icon-remove-outline" @click="clearFormData">重置搜索条件</el-button>
						<el-button type="text" icon="el-icon-caret-bottom">更多搜索条件</el-button>
						<!-- <i class=""></i> -->
					</div>
				</el-form>
			</div>
		</div>

		<!-- //表格 -->
		<el-row type="flex" align="middle" justify="space-between" style="margin-top: 18px">
			<el-col :span="18">
				<el-button type="danger" icon="el-icon-close" size="small" :disabled="multipleSelection.length == 0"
					@click="deletTableList(false)">移除选中</el-button>
				<el-button type="primary" v-if="showEdit" :disabled="multipleSelection.length == 0"
					@click="requst(false, '拍卖')" size="small">拍卖选中</el-button>
				<el-button type="primary" v-if="showEdit" :disabled="multipleSelection.length == 0"
					@click="requst(false, '报废')" size="small">报废选中</el-button>
			</el-col>
			<el-row type="flex" align="middle" justify="space-between">
				<div class="back-btn" @click="backHome">
					<img src="../../assets/images/u115.png">
					返回
				</div>
				<el-button type="primary" icon="el-icon-download" size="small" @click="gerBulletinHandlingCarExport">
					导出Excel</el-button>
			</el-row>
		</el-row>
		<div style="height:20px"></div>
		<el-table :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}" border
			v-loading="loading" :data="tableList" style="width: 100%" max-height="300"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="begin_time" label="公告开始" align="center" min-width="120" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="end_time" label="公告结束" min-width="120" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="car_no" label="车牌号" align="center" min-width="120" class-name="nopadding">
			</el-table-column>
			<el-table-column label="车架号/发动机号" min-width="150" class-name="nopadding">
				<template slot-scope="scope">
					<p class="table-number">{{ scope.row.frame_number }}</p>
					<p class="table-number">{{ scope.row.engine_number }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="brand" label="品牌" width="80" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="color" label="颜色" min-width="60" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="car_owner" label="车辆所有人" min-width="100" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="create_time" label="加入时间" min-width="150" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="parking_id" label="停车场自编号" min-width="110" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column label="公告批次号" min-width="120" align="center" class-name="nopadding">
				<template slot-scope="scope">
					<p class="table-number">{{ scope.row.batch_no }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="车辆状态" min-width="80" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="gone_type" label="车辆去向" align="center" min-width="110" class-name="nopadding">
				<template slot-scope="scope">
					<span>{{
            scope.row.gone_type ? scope.row.gone_type : "未处理"
          }}</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="300" v-if="showEdit" class-name="nopadding">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" @click="handleCar(0, scope.row)" v-if="
              scope.row.status == '公告到期' && scope.row.gone_type != '拍卖'
            ">拍卖</el-button>
					<el-button size="mini" type="primary" @click="handleCar(1, scope.row)" v-if="
              scope.row.status == '公告到期' && scope.row.gone_type != '报废'
            ">报废</el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">移除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background
				layout="total,prev, pager, next,jumper" :total="pagination.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				id: "",
				showAddDialog: false, //新增弹出框
				dialogFormVisible: false, //编辑弹出框
				activeName: "second",
				typeList: [{
						name: "全部批次",
						value: "",
					},
					{
						name: "公告中",
						value: "bulletin_processing",
					},
					{
						name: "公告到期",
						value: "bulletin_end",
					},
					{
						name: "已拍卖",
						value: "已拍卖",
					},
					{
						name: "已报废",
						value: "已报废",
					},
				],
				sendData: {
					type: "",
					time: "",
				},
				loading: false,
				showViewer: false, // 显示查看器
				url: "", //当前图片地址;
				rules: {
					batch_no: [{
						required: true,
						message: "请输入批次号",
						trigger: "blur",
					}, ],
					begin_time: [{
						required: true,
						message: "请选择开始时间",
						trigger: "change",
					}, ],
					end_time: [{
						required: true,
						message: "请选择结束时间",
						trigger: "change",
					}, ],
				}, //验证规则
				form: {
					batch_no: "",
					begin_time: "",
					end_time: "",
					remarks: "",
				},
				showEdit: false, //是否需要编辑
				multipleSelection: [], //表格选中id数组集合
				carIdlist: [], //表格选中车辆id数组集合
				formData: {
					car_no: "",
					frame_number: "",
					batch_no: "",
					remarks: "",
					unit_name: "",
					agent_name: "",
					storage_time: "",
					storage_time_begin: "",
					storage_time_end: "",
					bulletin_status: "",
				}, //搜索条件
				pagination: {
					index: 0,
					total: 0,
				}, //翻页数据
				tableList: [],

				ExcelData: "", //表格数据
				pickerOptions: {
					shortcuts: [{
							text: "最近一周",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近三个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
			};
		},
		computed: {
			...mapState(["SelectInfo"]),
		},
		mounted() {
			this.formData.batchId = this.$route.query.id;
			this.showEdit = this.$route.query.showEdit;
			this.loadData();
		},
		methods: {
			cheackedType(item) {
				this.sendData.type = item.value;

				if (item.value == "bulletin_processing" || item.value == "bulletin_end") {
					this.formData.bulletin_status = item.value;
					this.formData.gone_type = "";
				} else {
					if (item.name == "全部") {
						this.formData.bulletin_status = "";
						this.formData.gone_type = "";
					} else {
						this.formData.bulletin_status = "";
						this.formData.gone_type = item.value;
					}
				}
				this.loadData();
			},
			backHome() {
				this.$router.replace("notice")
			},
			handleAdd() {
				this.showAddDialog = true;
				this.$refs.AddNoticedialog.getList();
			},
			//重置搜索条件
			clearFormData() {
				this.$refs["form"].resetFields();
				this.loadData();
			},
			handleDelete(index, row) {
				this.deletTableList([row.bulletin_car_id]);
			},
			gerBulletinHandlingCarExport(batch_id) {
				window.open(this.ExcelData);
			},
			// 关闭查看器
			closeViewer() {
				this.showViewer = false;
			},
			/**
			 * 编辑确认修改
			 */
			async confirmEdit() {
				let res = await this.http.apiPost(
					"v1/Core/BulletinBatchEdit",
					this.form,
					true
				);
				if (res.flag) {
					this.dialogFormVisible = false;
					this.$notify({
						title: "温馨提示",
						message: "修改成功",
						type: "success",
						duration: 1500,
					});
					this.loadData();
				} else {
					this.$message(res.msg);
				}
			},
			/**
			 * 处理车辆
			 */
			handleCar(stutus, row) {
				if (stutus == 0) {
					stutus = "拍卖";
				} else {
					stutus = "报废";
				}
				if (row.gone_type == stutus) return;
				this.requst([row.id], stutus);
			},
			async requst(list, type) {
				if (!list) list = this.carIdlist;
				let res = await this.http.apiPost(
					"v1/Core/BulletinHandlingCarGone", {
						list,
						gone_type: type,
					},
					true
				);
				if (res.flag) {
					this.$notify({
						title: "温馨提示",
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.loadData();
				} else {
					this.$message(res.msg);
				}
			},
			/**
			 * 获取表格数据
			 */
			async loadData() {
				this.loading = true;
				var sendData = this.formData;
				if (this.formData.storage_time.length == 2) {
					sendData.storage_time_begin = this.formData.storage_time[0];
					sendData.storage_time_end = this.formData.storage_time[1];
				} else {
					sendData.storage_time_begin = "";
					sendData.storage_time_end = "";
				}
				sendData.index = this.pagination.index;
				var res = await this.http.apiPost(
					"v1/Core/BulletinHandlingCarPage",
					sendData
				);
				this.loading = false;
				if (res.flag) {
					let data = res.data.page;
					this.pagination.total = data.total;
					this.tableList = data.rows;
					this.ExcelData = res.data.url;
				} else {
					this.$message(res.msg);
				}
			},
			//表格选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val.map((v) => {
					v = v.bulletin_car_id;
					return v;
				});
				this.carIdlist = val.map((v) => {
					v = v.id;
					return v;
				});
			},
			/**
			 * @param {Object} list id集合
			 * 单个以及多条删除表格列表数据
			 */
			deletTableList(list) {
				//不传参数则为批量删除，取multipleSelection  中的id集合
				if (!list) list = this.multipleSelection;
				this.$confirm("此操作会永久删除选中数据, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(async () => {
					let res = await this.http.apiPost("v1/Core/BulletinHandlingCarDelete", {
						list,
					});
					if (res.flag) {
						this.$notify({
							title: "温馨提示",
							message: "删除成功",
							type: "success",
							duration: 1500,
						});
						this.loadData();
					} else {
						this.$message(res.msg);
					}
				});
			},
			//页数变化
			handleCurrentChange(val) {
				this.pagination.index = val - 1;
				this.loadData();
			},
		},
	};
</script>

<style>
	.nopadding {
		padding: 8px 0 !important;
	}
</style>
<style lang="scss">
	.back-btn {
		margin-right: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 14px;
		width: 28px;
		height: 60px;
		justify-content: center;
		cursor: pointer;

		img {
			width: 28px;
			height: 18px;
		}
	}

	.el-icon-circle-close {
		color: #ffffff !important;
	}

	.out {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.content-box {
		padding: 10px 30px 0;

		.table-number {
			margin: 0;
			color: #3399ff;
			font-size: 13px;
			text-decoration: underline;
			padding: 0;
		}

		.pagination {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;
		}

		.seach-box {
			border-radius: 5px;
			border: 0.5px solid #cccccc;

			.form-box {
				padding: 17px 29px 13px 29px;
			}
		}
	}
</style>
