import Vue from 'vue'
import Router from 'vue-router'
import Manage from '../view/Manage'
import home from '../view/home'
import menu from '../view/menu/index.vue'
import reportform from "../view/menu/reportform.vue"
import remind from "../view/menu/remind.vue"
import notice from "../view/menu/notice.vue"
import EditCar from "../view/menu/EditCar.vue"
import processing from "../view/menu/home.vue"
import store from '../store/index.js'
import message from "../view/message.vue"
import http from "../api/http.js";
import dispatch from '../view/menu/dispatch/index.vue';
import errorPage from '../view/sys/404.vue'
import noPermission from '../view/sys/401.vue'
import addDispatch from '../view/menu/dispatch/add.vue';
import carStatistics from "../view/menu/statistics/car_statistics.vue"
import carEmpty from "../view/menu/statistics/empty.vue"
import {
	Message
} from 'element-ui';
Vue.use(Router)

const router = new Router({
	routes: [{
			path: '/',
			redirect: {
				name: 'home'
			},

		},
		{
			path: '/web',
			redirect: {
				name: 'home'
			},
		},
		
		{
			path: '/web',
			name: 'manage',
			component: Manage,
			children: [{
					// UserProfile 会被渲染在 User 的 <router-view> 中
					path: 'home',
					name: "home",
					component: home
				},
				{
					path: '/supervise',
					name: "supervise",
					component: menu,
					redirect: {
						name: 'processing'
					},
					children: [{
							// 派单列表
							path: 'adddispatch',
							name: "adddispatch",
							component: addDispatch
						},
						{
							// 派单列表
							path: 'dispatch',
							name: "dispatch",
							component: dispatch
						}, {
							// 报表
							path: 'reportform',
							name: "reportform",
							component: reportform
						}, {
							// 管理中心
							path: 'processing',
							name: "processing",
							component: processing
						},
						//提醒设置
						{
							path: 'remind',
							name: "remind",
							component: remind
						}, //公告管理
						{
							path: 'notice',
							name: "notice",
							component: notice
						}, , //公告管理
						{
							path: 'editcar',
							name: "editcar",
							component: EditCar
						},
						//车辆统计
						{
							path: 'carStatistics',
							name: "carStatistics",
							component: carStatistics
						}
						,{
							path: 'carEmpty',
							name: "carEmpty",
							component: carEmpty
						}

					]
				},
				{
					// UserProfile 会被渲染在 User 的 <router-view> 中
					path: 'message',
					name: "message",
					component: message
				},
				{
					// UserProfile 会被渲染在 User 的 <router-view> 中
					path: '404',
					name: "404",
					component: errorPage,
					meta: {
						authentication: true
					}
				},
				{
					// UserProfile 会被渲染在 User 的 <router-view> 中
					path: '401',
					name: "401",
					component: noPermission,
					meta: {
						authentication: true
					}
				},

			]
		},
		{
			path: '*',
			redirect: {
				name: '404'
			},
		},
	]
})

export default {
	router: router
}
// 全局路由守卫
router.beforeEach(async (to, from, next) => {
	let routeName = to.name;
	let isHome = to.name == 'home' ? true : false;
	let authentication = to.meta.authentication;
	let menu = await store.dispatch("getUserInfo");
	console.log(routeName)
	//不需要鉴权或者是首页
	if (isHome || authentication) {
		next();
	} else {
		if(menu){
			//如果存在菜单
			let hasRouter = menu.some(v => v.route_address == routeName);
			if (hasRouter) {
				next();
			} else {
				next('/web/401')
			}
		}else{
			next("/home")
		}
		
	}
})
