<template>
	<el-dialog :title="title+'待入场登记'" :visible.sync="show" @closed="close" append-to-body width="990px">
		<el-form :model="form" size="mini" ref="ruleForm" :rules="rules">
			<el-row type="flex" align="middle">
				<el-col :span="7">
					<el-form-item label="车牌号:" :label-width="formLabelWidth">
						<el-input v-model="form.car_no" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="车架号:" :label-width="formLabelWidth">
						<el-input v-model="form.frame_number" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="发动机号:" prop="engine_number" :label-width="formLabelWidth">
						<el-input v-model="form.engine_number" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="7">
					<el-form-item label="车身颜色:" :label-width="formLabelWidth">
						<el-select v-model="form.color" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.color" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="品牌:" :label-width="formLabelWidth">
						<el-select v-model="form.brand" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.brands" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="车型:" :label-width="formLabelWidth">
						<el-select v-model="form.model" filterable  placeholder="请选择车型">
							<el-option v-for="item in SelectInfo.model" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="7">

					<el-form-item label="新旧程度:" :label-width="formLabelWidth">
						<el-select v-model="form.newness" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.new_level" :key="item.name" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="7">
					<el-form-item label="车辆所有人:" :label-width="formLabelWidth">
						<el-input v-model="form.car_owner" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="扣押日期:" prop="seizure_date" :label-width="formLabelWidth">
						<el-date-picker style="width: 100%;" size="mini" v-model="form.seizure_date" type="date" format="yyyy-MM-dd"
						 value-format="yyyy-MM-dd">
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="办案单位:" :label-width="formLabelWidth">
						<el-select v-model="form.unit_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.unit" :key="item.ID" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="7">
					<el-form-item label="案件编号:" prop="case_code" :label-width="formLabelWidth">
						<el-input v-model="form.case_code" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="案件名称:" prop="case_name" :label-width="formLabelWidth">
						<el-input v-model="form.case_name" autocomplete="off"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="经办人:" :label-width="formLabelWidth">
						<el-select v-model="form.agent_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.agenter" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="20">
					<el-form-item label="详细内容:" :label-width="formLabelWidth">
						<el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.details_content">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="confirm">登记待入场</el-button>
			<el-button type="danger" @click="close">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	var emptyForm = {
		unit_name: '',
		agent_name: '',
		car_no: '',
		frame_number: '',
		engine_number: "",
		car_owner: "",
		brand: '',
		model: '',
		color: '',
		newness: '',
		seizure_date: '',
		details_content: '',
		id: '',
		parking_name: '',
		case_name: "",
		case_code: "",
		parking_no: "",
	};
	import {
		mapState
	} from "vuex";
	export default {
		name: "addDialog",
		props: {
			showDialog: {
				type: Boolean,
				default: false,
			},
			caseType: {
				type: String,
				default: "type_case"
			},
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				title: "",
				show: false,
				form: {},
				rules: {
					seizure_date: [{
						required: true,
						message: '请选择或输入扣押时间',
						trigger: 'change'
					}],
					engine_number: [{
						required: true,
						message: '请填写发动机号',
						trigger: 'change'
					}],
					parking_name: [{
						required: true,
						message: '请选择或输入停车场',
						trigger: 'change'
					}],
					parking_no: [{
						required: true,
						message: '请选择或输入停车场自编号',
						trigger: 'change'
					}],
					case_name: [{
						required: true,
						message: '请输入案件名称',
						trigger: 'blur'
					}],
					case_code: [{
						required: true,
						message: '请输入案件编号',
						trigger: 'blur'
					}],
				},
				formLabelWidth: '120px',
				isconfirm: false, //是否为请求接口
			};
		},
		computed: {
			...mapState(["SelectInfo"]),
		},
		created() {
			switch (this.caseType) {
				case "type_case":
					this.title = "涉案车辆";
					break
				case "type_accident":
					this.title = "事故车辆";
					break
				case "type_illegal":
					this.title = "违法车辆";
					break
			}
		},
		watch: {
			caseType: function(val) {
				switch (val) {
					case "type_case":
						this.title = "涉案车辆";
						break
					case "type_accident":
						this.title = "事故车辆";
						break
					case "type_illegal":
						this.title = "违法车辆";
						break
				}
			},
			// formData: function(val) {
			// //	console.log(val);
			// 	if (val.id) {
			// 		this.form = JSON.parse(JSON.stringify(val));
			// 	}
			// },
			showDialog: function(val) {
				this.show = val;
				if(val)this.$store.dispatch("getSelectInfo");
				if (!val && !this.isconfirm) {
					this.clear();
				}
			}
		},
		methods: {
			setFormData(data) {
				this.form = JSON.parse(JSON.stringify(data));
			},
			close() {
				this.$emit('update:showDialog', false);
			},
			//重置表单
			clear() {
				this.form = JSON.parse(JSON.stringify(emptyForm));
				this.isconfirm=false;
				this.$nextTick(()=>{
				this.$refs["ruleForm"].clearValidate();
				})
			},
			parkChange(name) {
				var item = this.SelectInfo.park.find(v => v.parking_name == name);
				if (item) {
					this.form.parking_no = item.parking_no;
				} else {
					this.form.parking_no = ""
				}
			},
			parkNoChange(parking_no) {
				var item = this.SelectInfo.park.find(v => v.parking_no == parking_no);
				if (item) {
					this.form.parking_name = item.parking_name;
				}
			},
			confirm() {
				this.$refs["ruleForm"].validate((valid) => {
					if (valid) {
						this.isconfirm = true;
						this.$emit("confirm", this.form);
					} else {
						return false;
					}
				});

			},
		}
	};
</script>

<style>
</style>
