<template>
	<div class="u-flex-col padding-30">
		<h3 class="u-text-center margin-30">放空统计</h3>
		<el-row type="flex" justify="end" style="margin-bottom: 10px">
			<el-date-picker v-model="time" size="small" type="daterange" :clearable="false" align="right" unlink-panels
				range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" e format="yyyy 年 MM 月 dd 日"
				:picker-options="pickerOptions" value-format="yyyy-MM-dd" @change="loadData">
			</el-date-picker>
			<el-button type="primary" class="margin-left-20 " size="small" @click="outerExcel">导出Excel</el-button>
			<el-button type="primary" class="margin-left-10 margin-right-40" size="small" @click="navtoCar">车辆统计
			</el-button>
		</el-row>
		<el-table :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}" border
			:data="tableList" style="width: 100%">
			<el-table-column fixed prop="address" label="事故地点" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="accident_type" label="事故类型" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="create_time" label="派单时间" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="work_name" align="center" label="施救人员" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="is_time" align="center" label="接单时间" class-name="nopadding">
			</el-table-column>

			<el-table-column prop="voidance_time" align="center" min-width="120" label="放空时间" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="voidance_remark" align="center" min-width="120" label="放空原因" class-name="nopadding">
			</el-table-column>
		</el-table>
		<el-row type="flex" align="middle" justify="end" class="margin-top-30">
			<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background
				:page-size="10" layout="total,prev, pager, next,jumper" :total="pagination.total">
			</el-pagination>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				time: '',
				pagination: {
					index: 1,
					total: 0,
				},
				exporturl:'',
				tableList: [],
				pickerOptions: {
					disabledDate: function(val) {
						return val.getTime() > Date.now() + 3600 * 1000 * 24;
					},
					shortcuts: [{
							text: "最近一周",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近三个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一年",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
			}
		},
		mounted() {
			//初始化月份
			var day = new Date(Date.now()).getDate();
			var month = new Date().getMonth() + 1;
			var year = new Date().getFullYear();
			const begin_time = year + "-" + month + "-" + "1";
			const end_time = year + "-" + month + "-" + day;
			this.time = [begin_time, end_time];
			this.loadData();
		},
		methods: {
			async loadData() {
				const sendData = {
					time_begin: '',
					time_end: ''
				};
				sendData.index=this.pagination.index-1;
				if (this.time) {
					sendData.time_begin = this.time[0];
					sendData.time_end = this.time[1];
				}
				let res = await this.http.apiPost('v1/core/OrderVoidance', sendData, true);
				if(res.flag){
					this.tableList=res.data.list.rows;
					this.pagination.total=res.data.list.total;
					this.exporturl=res.data.exporturl;
				}else{
					this.$message.error(res.msg);
				}
			},
			outerExcel() {
				window.open(this.exporturl);
			},
			navtoCar() {
				this.$router.back();
			},
			//页数变化
			handleCurrentChange(val) {
				this.pagination.index = val ;
				this.loadData();
			},
		}
	}
</script>

<style>
</style>
