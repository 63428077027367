<template>
	<div>
		<el-dialog title="请输入退回备注" :visible.sync="centerDialogVisible" width="30%" center top="30vh" append-to-body >
			<el-form :model="sendData" size="mini" ref="formRoule" :rules="rules1">
				<el-form-item label="退回审核备注:" prop="auditRemarks">
					<el-input v-model="sendData.auditRemarks" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirmSend">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="title+'入场审核'" :visible.sync="show" @closed="close" width="990px">
			<el-form :model="form" size="mini" ref="ruleForm" label-position="left"   :rules="rules">
				<el-row type="flex" align="middle" justify="space-between">
					<el-col :span="7">
						<el-form-item label="车牌号:" :label-width="formLabelWidth">
							<el-input v-model="form.car_no" autocomplete="off"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="车架号:" :label-width="formLabelWidth">
							<el-input v-model="form.frame_number" autocomplete="off"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="发动机号:" prop="engine_number" :label-width="formLabelWidth">
							<el-input v-model="form.engine_number" autocomplete="off"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" justify="space-between">
					<el-col :span="7">
						<el-form-item label="车身颜色:" :label-width="formLabelWidth">
							<el-select v-model="form.color" filterable allow-create placeholder="请选择/输入">
								<el-option v-for="item in SelectInfo.color" :key="item.id" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="品牌:" :label-width="formLabelWidth">
							<el-select v-model="form.brand" filterable allow-create placeholder="请选择/输入">
								<el-option v-for="item in SelectInfo.brands" :key="item.id" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="车型:" :label-width="formLabelWidth">
							<el-select v-model="form.model" filterable  placeholder="请选择车型">
								<el-option v-for="item in SelectInfo.model" :key="item.id" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row type="flex" align="middle" justify="space-between" >
					<el-col :span="7">
						<el-form-item label="车辆所有人:" :label-width="formLabelWidth">
							<el-input v-model="form.car_owner" autocomplete="off"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="扣押日期:" prop="seizure_date" :label-width="formLabelWidth">
							<el-date-picker style="width: 100%;" size="mini" v-model="form.seizure_date" type="date" format="yyyy-MM-dd"
							 value-format="yyyy-MM-dd">
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="扣车民警:" prop="withhold_police_name" :label-width="formLabelWidth">
							<el-input v-model="form.withhold_police_name" autocomplete="off"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="20">
						<el-form-item label="详细内容:" :label-width="formLabelWidth">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.details_content">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			
				<el-row type="flex" align="middle" justify="space-between">
					<el-col :span="7">
						<el-form-item label="停车场:" prop="parking_name" :label-width="formLabelWidth">
							<el-select v-model="form.parking_name" filterable allow-create placeholder="请选择/输入">
								<el-option v-for="item in SelectInfo.park" :key="item.id" :label="item.parking_name" :value="item.parking_name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label="附件:" :label-width="formLabelWidth">
							<el-upload v-if="showDialog" :file-list="fileList" :action="http.url('/api/v1/Core/Upload')" :on-success="uploadSuccess"
							 :headers="http.headers" name="data" list-type="picture-card" :on-preview="handlePictureCardPreview"
							 :before-remove="handleRemove">
								<i slot="default" class="el-icon-plus"></i>
								<div slot="file" slot-scope="{file}">
									<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
									<span class="el-upload-list__item-actions">
										<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
											<i class="el-icon-zoom-in"></i>
										</span>
										<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
							</el-upload>
							<el-dialog :modal="false" :visible.sync="dialogVisible">
								<img width="50%" alt="">
								<el-image style="width: 100%" :src="dialogImageUrl" fit="fill"></el-image>
							</el-dialog>
						</el-form-item>
					</el-col>

				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label="备注:" :label-width="formLabelWidth">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.storage_remarks">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" >
					<el-col :span="7">
						<el-form-item label="施救人员:" :label-width="formLabelWidth">
							<span>{{form.work_name}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="施救人员电话:" :label-width="formLabelWidth">
							<span>{{form.work_phone}}</span>
						</el-form-item>
					</el-col>
				</el-row>
					<el-row type="flex" align="middle" >
						<el-form-item label="事故地点:" :label-width="formLabelWidth">
							<span>{{form.accident_location||""}}</span>
						</el-form-item>
					</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirm">入场审核通过</el-button>

				<el-button type="danger" @click="centerDialogVisible=true">人场审核退回</el-button>
				<el-button type="danger" @click="close">取 消</el-button>
			</div>
		</el-dialog>
	</div>


</template>

<script>
	var emptyForm = {
		unit_name: '', //单位名称
		agent_name: '', //经办人姓名
		parking_receive_name: "", //停车场人员
		car_no: '', //车牌号
		frame_number: '', //	车架号
		engine_number: "", //发动机号
		seizure_date: "", //扣押日期
		car_owner: "", //车辆所有人
		brand: '', //品牌
		model: '', //车型
		color: '', //颜色
		newness: '', //新旧成都
		details_content: '', //详细内容
		id: '',
		storage_time: "", //入库时间
		parking_name: '', //停车场名称
		parking_number: "", //停车场车位号
		case_name: "", //案件名称
		case_code: "", //案件编码
		parking_receive_name: "", //接收人
		parking_receive_phone: "", //停车场接收人电话
		audit_remarks: "", //备注
		parking_no: "", //自编号

	};
	import {
		mapState
	} from "vuex";
	export default {
		name: "addDialog",
		props: {
			showDialog: {
				type: Boolean,
				default: false,
			},
			caseType: {
				type: String,
				default: "type_case"
			},
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				title: "",
				show: false,
				disabled: false,
				dialogImageUrl: '', //图片预览地址
				dialogVisible: false, //图片预览
				form: emptyForm,
				fileList: [],
				centerDialogVisible: false,
				auditRemarks: "",
				sendData: {
					recordId: "",
					auditRemarks: ""
				},
				rules1: {
					auditRemarks: [{
						required: true,
						message: '请输入退回审核备注',
						trigger: 'blur'
					}],
				},
				rules: {},
				formLabelWidth: '100px',
				isconfirm: false, //是否为请求接口
			};
		},
		computed: {
			...mapState(["SelectInfo"]),
		},
		created() {
			switch (this.caseType) {
				case "type_case":
					this.title = "涉案车辆";
					break
				case "type_accident":
					this.title = "事故车辆";
					break
				case "type_illegal":
					this.title = "违法车辆";
					break
			}
		},
		watch: {
			caseType: function(val) {
				switch (val) {
					case "type_case":
						this.title = "涉案车辆";
						break
					case "type_accident":
						this.title = "事故车辆";
						break
					case "type_illegal":
						this.title = "违法车辆";
						break
				}
			},
			// formData: function(val) {
			// //	console.log(val);
			// 	if (val.id) {
			// 		this.form = JSON.parse(JSON.stringify(val));
			// 	}
			// },
			showDialog: function(val) {
				this.show = val;
				if(val)this.$store.dispatch("getSelectInfo");
				if (!val && !this.isconfirm) {
					this.clear();
				}
			}
		},
		methods: {
			async setFormData(data) {
				let res = await this.http.apiGet("v1/Core/AuditEdit", {recordId:data.id},true);
				if (res.flag) {
					this.form = res.data.record;
					this.fileList = res.data.recordimage;
				} else {
					this.$message.error(res.msg);
				}
			},
			close() {
				this.$emit('update:showDialog', false);
			},
			uploadSuccess(response, file, fileList) {
				if (response.flag) {
					this.fileList.unshift(response.data);
				} else {
					this.$message.error(response.msg);
				}
			},
			confirmSend() {
				this.$refs["formRoule"].validate(async (valid) => {
					if (valid) {
						this.sendData.recordId = this.form.id;
						var res = await this.http.apiGet("v1/Core/CaseAuditReturn", this.sendData, true);
						if (res.flag) {
							this.$emit("update:showDialog", false);
							this.clear();
							this.centerDialogVisible = false;
							this.$notify({
								title: '温馨提示',
								message: '操作成功',
								type: 'success',
								duration: 1500,
							});
							this.$emit("loadData");
						} else {
							this.$message.error(res.msg);
						}
					} else {
						return false;
					}
				});

			},
			//重置表单
			clear() {
				this.form = emptyForm;
				this.fileList = [];
				this.$nextTick(() => {
					this.$refs["ruleForm"].clearValidate();
				})
			},
			//删除图片
			async handleRemove(file, fileList) {
					this.fileList = this.fileList.filter(v => v.uid != file.uid);
			},
			parkChange(name) {
				var item = this.SelectInfo.park.find(v => v.parking_name == name);
				if (item) {
					this.form.parking_no = item.parking_no;
				} else {
					this.form.parking_no = ""
				}
			},
			parkNoChange(parking_no) {
				var item = this.SelectInfo.park.find(v => v.parking_no == parking_no);
				if (item) {
					this.form.park_name = item.parking_name;
				}
			},
			//图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			confirm() {
				this.$refs["ruleForm"].validate((valid) => {
					if (valid) {
						var image = [];
						this.fileList.forEach(v => {
							image.push(v.url);
						})
						this.form.image = image;
						this.isconfirm = true;
						this.$emit("confirm", this.form);
					} else {
						return false;
					}
				});

			},
		}
	};
</script>

<style>
</style>
