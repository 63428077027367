<template>
	<el-dialog :title="parkInfo.parking_name" :visible.sync="show" @open="open" :before-close="close" append-to-body
		width="500">
		<el-table :data="parkInfo.list" style="width: 100%" :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}" border>
			<el-table-column align="center" prop="car_type_name" label="车辆类型" >
			</el-table-column>
			<el-table-column align="center" prop="money" label="费用" >
				<template slot-scope="scope">
					<span>{{scope.row.money}}元/天</span>
				</template>
			</el-table-column>
		</el-table>
		<div slot="footer">
			<el-button type="danger" size="medium" @click="close">关 闭</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				parkInfo: {}
			}
		},
		methods: {
			close() {
				this.$emit("update:show", false);
			},
			async open() {
				let res = await this.http.apiGet('v1/core/ChargingStandard', null, true);
				if (res.flag) {
					console.log(res.data);
					let data = res.data;
					this.parkInfo = data[0];
				} else {
					this.$message.warning(res.msg);
				}
			},
		}
	}
</script>

<style>
</style>
