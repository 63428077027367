<template>
	<el-dialog :title="title+'复核'" :visible.sync="showDialog" width="990px" :before-close="closeDialog">
		<el-dialog title="请输入退回备注" :visible.sync="centerDialogVisible" width="30%" center top="30vh" append-to-body>
			<el-form :model="returnData" size="mini" ref="formRoule">
				<el-form-item label="退回审核备注:" prop="auditRemarks">
					<el-input v-model="returnData.auditRemarks" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirmSend">确 定</el-button>
			</span>
		</el-dialog>
		<el-form :model="form" size="mini" label-width="auto" label-position="left" class="form">
			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item label="车牌号:">
						<span>{{form.car_no||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="车架号:">

						<span>{{form.frame_number||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="发动机号:" prop="engine_number">
						<span>{{form.engine_number||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item label="车身颜色:">
						<span>{{form.color||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="品牌:">
						<span>{{form.brand||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="车型:">
						<span>{{form.model||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="8">

					<el-form-item label="新旧程度:">
						<span>{{form.newness||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8" v-if="caseType=='type_accident'">
					<el-form-item label="车辆所有人:">
						<span>{{form.car_owner||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">

				</el-col>
			</el-row>
			<div v-if="caseType=='type_accident'">
				<el-row type="flex" align="middle">
					<el-col :span="8">
						<el-form-item label="事故类型:">
							<span>{{form.accident_type||"-"}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item style="width: 100%;" label="事故时间:" prop="illegal_time">
							<span>{{form.accident_time||"-"}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="事故地点:" prop="accident_location ">
							<span>{{form.accident_location ||"-"}}</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle">
					<el-col :span="8">
						<el-form-item label="扣押日期:" prop="seizure_date">
							<span>{{form.seizure_date||"-"}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="扣车民警:" prop="withhold_police_name">
							<span>{{form.withhold_police_name||"-"}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="事故当事人:" prop="accident_person" v-if="caseType == 'type_accident'">
							<span>{{form.accident_person||"-"}}</span>
						</el-form-item>
						<el-form-item label="违法当事人:" prop="accident_person" v-if="caseType=='type_illegal'">
							<span>{{form.illegal_person||"-"}}</span>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
			<el-row type="flex" align="middle" v-if="caseType=='type_illegal'">
				<el-col :span="8">
					<el-form-item label="车辆所有人:">
						<span>{{form.car_owner||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item style="width: 100%;" label="违法时间:" prop="illegal_time">
						<span>{{form.illegal_time||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="违法地点:" prop="illegal_location">
						<span>{{form.illegal_location||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-if="caseType=='type_illegal'">

				<el-col :span="8">
					<el-form-item label="扣押日期:" prop="seizure_date">
						<span>{{form.seizure_date||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="扣车民警:" prop="withhold_police_name">
						<span>{{form.withhold_police_name||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="事故当事人:" prop="accident_person" v-if="caseType == 'type_accident'">
						<span>{{form.accident_person||"-"}}</span>
					</el-form-item>
					<el-form-item label="违法当事人:" prop="accident_person" v-if="caseType=='type_illegal'">
						<span>{{form.illegal_person||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-if="caseType=='type_case'">
				<el-col :span="8">
					<el-form-item label="车辆所有人:">
						<span>{{form.car_owner||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="扣押日期:" prop="seizure_date">
						<span>{{form.seizure_date||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<!-- <el-form-item label="办案单位:">
						<span>{{form.unit_name}}</span>
					</el-form-item> -->
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-if="caseType=='type_case'">
				<el-col :span="8">
					<el-form-item label="案件编号:" prop="case_code">
						<span>{{form.case_code||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="案件名称:" prop="case_name">
						<span>{{form.case_name||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="经办人:">
						<span>{{form.agent_name||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item style="width: 100%;" label="入库时间:" prop="storage_time">
						<span>{{form.storage_time||"-"}}</span>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item label="接收人:">
						<span>{{form.parking_receive_name||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="电话:">
						<span>{{form.parking_receive_phone||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item label="停车场:" prop="parking_name">
						<span>{{form.parking_name||"-"}}</span>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item label="车位号:">
						<span>{{form.parking_number||"-"}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">

				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="入场照片:">
						<el-image  
							style="width: 100px; height: 100px;margin-right: 10px;" :src="form.parking_place" fit="fill"
							 :z-index='9999' :preview-src-list="[form.parking_place]"></el-image>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="现场照片:">
						<el-image v-for="(item,index) in imageList" :key="index"
							style="width: 100px; height: 100px;margin-right: 10px;" :src="item" fit="fill"
							:preview-src-list="imageList" :z-index='9999'></el-image>
					</el-form-item>
				</el-col>

			</el-row>
			<el-row>
				<el-col :span="20">
					<el-form-item label="详细内容:">
						<span>{{form.details_content||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="20">
					<el-form-item label="备注:">
						<span>{{form.storage_remarks||"-"}}</span>
					</el-form-item>
				</el-col>
			</el-row>

		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="danger" style="float: left;" @click="centerDialogVisible=true">复核退回</el-button>
			<el-button type="primary" @click="confirm">复核通过</el-button>
			<el-button type="danger" @click="closeDialog">取 消</el-button>
		</div>
	</el-dialog>

</template>

<script>
	export default {
		props: {
			showDialog: {
				type: Boolean,
				default: false,
			},
			caseType: {
				type: String,
				default: "type_case"
			},
			id: {
				type: String,
				default: ''
			}
		},
		watch: {
			showDialog(show) {
				if (show) {
					this.loadData();
				}
			}
		},
		data() {
			return {
				centerDialogVisible: false, //退回弹窗
				form: {},
				imageList: [],
				returnData: {
					auditRemarks: ''
				},
			}
		},
		computed: {
			title() {
				switch (this.caseType) {
					case "type_case":
						return "涉案车辆";
					case "type_accident":
						return "事故车辆";
					case "type_illegal":
						return "违法车辆";
				}
			}
		},
		methods: {
			closeDialog() {
				this.centerDialogVisible = false;
				this.returnData.auditRemarks = '';
				this.form = {};
				this.$emit('update:showDialog', false)
			},
			async loadData() {
				let res = await this.http.apiGet('v1/core/GetWaitReview', {
					recordId: this.id
				}, true);
				console.log(res);
				if (res.flag) {
					this.form = res.data.record;
					this.imageList = res.data.recordimage.map(v => v.url);
				} else {
					this.$message.error(res.msg);
				}
			},
			/**
			 * 审核通过
			 */
			confirm() {
				this.returnData.recordId = this.form.id;
				this.returnData.is_success = true;
				this.caseWaitReview()
			},
			/**
			 * 审核不通过
			 */
			confirmSend() {
				this.returnData.recordId = this.form.id;
				this.returnData.is_success = false;
				this.caseWaitReview()
			},
			async caseWaitReview() {
				if (!this.returnData.is_success && !this.returnData.auditRemarks) return this.$message.warning(
					'请填写审核退还原因！')
				let res = await this.http.apiPost("v1/core/CaseWaitReview", this.returnData, true);
				if (res.flag) {
					this.$notify({
						title: '温馨提示',
						message: '操作成功',
						type: 'success',
						duration: 1500,
					});
					this.closeDialog();
					this.$emit("loadData");
				} else {
					this.$message.error(res.msg);
				}
			}
		}
	}
</script>

<style scoped>

</style>
