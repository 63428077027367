<template>
	<el-dialog :title="title+'结案出场'" @open="payDone=false" :visible.sync="show" @closed="close" width="990px"
		:before-close="closeDialog">
		<el-dialog :title="is_paying?'扫码收款':'请选择付款方式'" :show-close="!is_paying" :before-close="closePayDialog"
			:visible.sync="showCollection" width="500px" append-to-body center>
			<div class="u-text-center font-22" v-if="!is_paying">
				超时停车费：<span class="font-26 text-bold text-red">{{amount||0}}元</span>
			</div>
			<div class="u-text-center font-22" v-else>

				<span class="font-22 text-bold text-red">正等待顾客出示付款码...</span>
			</div>
			<span slot="footer" class="dialog-footer" v-if="!is_paying">
				<el-button type="primary" @click="payAmount(0)">现金收款</el-button>
				<el-button type="success" @click="payAmount(1)">扫码支付</el-button>
			</span>
			<span slot="footer" class="dialog-footer" v-else>
				<el-button type="danger" @click="closePay">取消收款</el-button>
			</span>
		</el-dialog>
		<Print ref="Print"></Print>
		<el-form :model="form" size="mini" label-width="100px" label-position="left" class="form">
			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item label="车牌号:">
						<span>{{form.car_no}}</span>
					</el-form-item>

				</el-col>
				<el-col :span="8">
					<el-form-item label="车架号:">

						<span>{{form.frame_number}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="发动机号:" prop="engine_number">
						<span>{{form.engine_number}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item label="车身颜色:">
						<span>{{form.color}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="品牌:">
						<span>{{form.brand}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="车型:">
						<span>{{form.model}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="8">

					<el-form-item label="新旧程度:">
						<span>{{form.newness}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8" v-if="caseType=='type_accident'">
					<el-form-item label="车辆所有人:">
						<span>{{form.car_owner}}</span>
					</el-form-item>
				</el-col>
				<!-- 	<el-col :span="8" v-if="caseType=='type_accident'">
					<el-form-item style="width: 100%;" label="交强险凭证号:" prop="illegal_time">
						<span>{{form.insurance_code}}</span>
					</el-form-item>
				</el-col> -->
			</el-row>
			<div v-if="caseType=='type_accident'">
				<el-row type="flex" align="middle">
					<el-col :span="8">
						<el-form-item label="事故类型:">
							<span>{{form.accident_type}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item style="width: 100%;" label="事故时间:" prop="illegal_time">
							<span>{{form.accident_time}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="事故地点:" prop="accident_location ">
							<span>{{form.accident_location }}</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle">
					<el-col :span="8">
						<el-form-item label="扣押日期:" prop="seizure_date">
							<span>{{form.seizure_date}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="扣车民警:" prop="withhold_police_name">
							<span>{{form.withhold_police_name}}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="事故当事人:" prop="accident_person">
							<span>{{form.accident_person}}</span>
						</el-form-item>
					
					</el-col>
				</el-row>
			</div>
			<el-row type="flex" align="middle" v-if="caseType=='type_illegal'">
				<el-col :span="8">
					<el-form-item label="车辆所有人:">
						<span>{{form.car_owner}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item style="width: 100%;" label="违法时间:" prop="illegal_time">
						<span>{{form.illegal_time}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="违法地点:" prop="illegal_location">
						<span>{{form.illegal_location}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-if="caseType=='type_illegal'">
				<el-col :span="8">
					<el-form-item label="强制措施编号:" prop="action_code">
						<span>{{form.action_code}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="扣押日期:" prop="seizure_date">
						<span>{{form.seizure_date}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="扣车民警:" prop="withhold_police_name">
						<span>{{form.withhold_police_name}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-if="caseType=='type_case'">
				<el-col :span="8">
					<el-form-item label="车辆所有人:">
						<span>{{form.car_owner}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="扣押日期:" prop="seizure_date">
						<span>{{form.seizure_date}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-if="caseType=='type_case'">
				<el-col :span="8">
					<el-form-item label="案件编号:" prop="case_code">
						<span>{{form.case_code}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="案件名称:" prop="case_name">
						<span>{{form.case_name}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="经办人:">
						<span>{{form.agent_name}}</span>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item style="width: 100%;" label="入库时间:" prop="storage_time">
						<span>{{form.storage_time}}</span>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item label="接收人:">
						<span>{{form.parking_receive_name}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="电话:">
						<span>{{form.parking_receive_phone}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle">
				<el-col :span="8">
					<el-form-item label="停车场:" prop="parking_name">
						<span>{{form.parking_name}}</span>
					</el-form-item>
				</el-col>
				
				<el-col :span="8">
					<el-form-item label="车位号:">
						<span>{{form.parking_number}}</span>
					</el-form-item>
				</el-col>
				<el-col :span="8">
						
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="入场照片:">
						<el-image style="width: 100px; height: 100px;margin-right: 10px;" :src="form.parking_place"
							fit="fill" :z-index='9999' :preview-src-list="[form.parking_place]"></el-image>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="现场照片:">
						<el-image v-for="(item,index) in imageList" :key="index"
							style="width: 100px; height: 100px;margin-right: 10px;" :src="item" fit="fill"
							:preview-src-list="imageList" :z-index='9999'></el-image>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="20">
					<el-form-item label="详细内容:">
						<span>{{form.details_content}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="20">
					<el-form-item label="备注:">
						<span>{{form.storage_remarks}}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-if="form.audit_remarks">
				<el-col :span="20">
					<el-form-item label="审核退回备注:">
						<span class="text-bold font-22">{{form.audit_remarks}}</span>
					</el-form-item>
				</el-col>
			</el-row>

		</el-form>
		<el-divider content-position="center">结案情况</el-divider>
		<el-form :model="sendData" :rules='rules' size="mini" label-position="left" ref="ruleForm" label-width="auto">
			<el-row type="flex" align="middle" :gutter="20">
				<el-col :span="8">
					<el-form-item label="放行单编号:" prop="release_no">
						<el-input v-model="sendData.release_no"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="放车单日期:" prop="release_date">
						<el-date-picker @change="changeDate" style="width: 100%;" size="mini"
							v-model="sendData.release_date " type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd">
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="车辆去向:" prop="gone_type">
						<el-select style="width: 100%;" v-model="sendData.gone_type" placeholder="请选择车辆去向">
							<el-option v-for="item in SelectInfo.gone_type" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" :gutter="20">

				<el-col :span="8">
					<el-form-item label="取车人:" prop="gone_type">
						<el-input v-model="sendData.take_name "></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="取车人电话:" prop="gone_type">
						<el-input v-model="sendData.take_phone" maxlength="11"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="取车人证件号:" prop="gone_type">
						<el-input v-model="sendData.license_no"></el-input>
					</el-form-item>
				</el-col>

			</el-row>
			<el-row :gutter="20">
				<el-col :span="16">
					<el-form-item label="放车附件：">
						<div class="upload">
							<el-upload class="upload-demo" :action="http.url('/api/v1/Core/Upload')"
								:on-success="uploadSuccess" list-type="picture"
								accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PBG,.GIF" :before-remove="beforeRemove"
								:headers="http.headers" multiple :file-list="fileList">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<span class="text-bold">(附件多个上传 如放车单，证件照片均可)</span>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="20">
					<el-form-item label="结案备注：">
						<el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="sendData.case_remarks">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="confirm">结案出场</el-button>
			<el-button type="danger" @click="close">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import Scan from "@/assets/plugins/scan.js"
	import Print from "@/components/Print.vue"
	var emptyForm = {
		unit_name: '', //单位名称
		agent_name: '', //经办人姓名
		parking_receive_name: "", //停车场人员
		car_no: '', //车牌号
		frame_number: '', //	车架号
		engine_number: "", //发动机号
		seizure_date: "", //扣押日期
		car_owner: "", //车辆所有人
		brand: '', //品牌
		model: '', //车型
		color: '', //颜色
		newness: '', //新旧成都
		details_content: '', //详细内容
		id: '',
		storage_time: "", //入库时间
		park_name: '', //停车场名称
		parking_number: "", //停车场车位号
		case_name: "", //案件名称
		case_code: "", //案件编码
		parking_receive_phone: "", //停车场接收人电话
		gone_type: "返还车辆所有人"
	};
	import {
		mapState,
		mapGetters
	} from "vuex";
	export default {
		name: "addDialog",
		props: {
			showDialog: {
				type: Boolean,
				default: false,
			},
			caseType: {
				type: String,
				default: "type_case"
			},
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		components: {
			Print
		},
		data() {
			return {
				showCollection: false,
				is_paying: false, //是否是扫码支付
				palying: false, //是否正在查单
				payTimer: false,
				payDone: false, //支付成功
				title: "",
				show: false,
				dialogImageUrl: '', //图片预览地址
				dialogVisible: false, //图片预览
				imageList: [],
				form: JSON.parse(JSON.stringify(emptyForm)),
				sendData: {
					case_remarks: "", //结案备注
					release_no: "", //放车单编号
					release_date: "", //放车日期
					attachment: [], //附件
					take_name: '', //取车人
					take_phone: '', //取车人手机号
					license_no: '' //取车人证件号
				},
				fileList: [],
				rules: {
					release_no: [{
						required: true,
						message: '请输入放行单编号',
						trigger: 'change'
					}],
					release_date: [{
						required: true,
						message: '请选择放车日期',
						trigger: 'change'
					}],

				},
				formLabelWidth: '100px',
				isconfirm: false, //是否为请求接口
				dics: {}, //打印数据
				scan: null,
				amount: 0,
			};
		},
		computed: {
			...mapState(["SelectInfo"]),
			...mapGetters({
				Info: "Info",
			})
		},
		created() {
			switch (this.caseType) {
				case "type_case":
					this.title = "涉案车辆";
					break
				case "type_accident":
					this.title = "事故车辆";
					break
				case "type_illegal":
					this.title = "违法车辆";
					break
			}
			this.scan = Scan.Scan.init();

			function dateFormat(fmt, date) {
				let ret;
				const opt = {
					"Y+": date.getFullYear().toString(), // 年
					"m+": (date.getMonth() + 1).toString(), // 月
					"d+": date.getDate().toString(), // 日
					"H+": date.getHours().toString(), // 时
					"M+": date.getMinutes().toString(), // 分
					"S+": date.getSeconds().toString() // 秒
					// 有其他格式化字符需求可以继续添加，必须转化成字符串
				};
				for (let k in opt) {
					ret = new RegExp("(" + k + ")").exec(fmt);
					if (ret) {
						fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
					}
				}
				return fmt;
			}
			let date = new Date()
			this.sendData.approver_date = dateFormat("YYYY-mm-dd HH:MM", date)
		},
		watch: {
			caseType: function(val) {
				switch (val) {
					case "type_case":
						this.title = "涉案车辆";
						break
					case "type_accident":
						this.title = "事故车辆";
						break
					case "type_illegal":
						this.title = "违法车辆";
						break
				}
			},
			showDialog: function(val) {
				this.show = val;
				if (val) this.$store.dispatch("getSelectInfo");
				if (!val) {
					this.clear();
				}
			}
		},
		methods: {
			//解决图片预览关闭弹窗
			closeDialog(done) {
				const a = document.getElementsByClassName('el-image-viewer__wrapper')[0];
				if (!a) {
					done()
				}
			},
			async setFormData(data) {
				let res = await this.http.apiGet("v1/Core/RecordClosed", {
					recordId: data.id
				}, true);
				this.sendData.record_id = data.id;
				if (res.flag) {
					this.form = res.data.record;
					this.sendData.gone_type = "返还车辆所有人";
					this.imageList = res.data.recordimage.map(v => v.url);
					//如果已填写过信息
					if (res.data.closed?.release_no) {
						this.sendData = res.data.closed;
						this.fileList = res.data.goneimage;
					}
				} else {
					this.$message.error(res.msg);
				}
			},
			closePayDialog() {
				if (!this.is_paying) this.showCollection = false;
			},
			/**
			 * 支付
			 * @param type {Number}  = [0为现金 1为扫码支付] 
			 */
			payAmount(type) {
				if (type == 1) {
					this.is_paying = true
					this.scan.read(code => {
						if (this.palying) return;
						this.scanPay(code);
					})
				} else {
					this.cashPayment();
				}
			},
			/**
			 * 现金支付
			 */
			cashPayment() {
				this.$confirm('请确认是否收到现金,点击确定立即结案, 是否继续?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'success'
				}).then(async () => {
					let res = await this.http.apiPost('v1/core/CashPayment', {
						id: this.sendData.record_id,
						time: this.sendData.release_date
					}, true);
					if (res.flag) {
						this.closePay();
						this.payDone = true;
						this.confirm();
					} else {
						this.$message.error(res.msg);
					}
				}).catch(() => {

				});

			},
			/**
			 * 扫码支付
			 * @param {String} code
			 */
			async scanPay(code) {
				this.palying = true;
				let res = await this.http.apiPost('v1/core/GeneralPayment', {
					code,
					id: this.sendData.record_id,
					time: this.sendData.release_date
				}, true);
				if (res.flag) {
					this.generalPaymentQuery(res.data.id);
				} else {
					this.palying = false;
					this.$message.error(res.msg);
				}
			},
			async generalPaymentQuery(id) {
				if (this.payTimer) clearTimeout(this.payTimer);
				const loading = this.$loading({
					lock: true,
					text: '正在查询支付结果',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.6)'
				});
				let res = await this.http.apiGet('v1/core/GeneralPaymentQuery', {
					id
				})
				this.palying = false;
				if (res.flag) {
					loading.close();
					this.$alert('支付成功', '收款成功', {
						confirmButtonText: '确定',
						type: 'success',
						callback: action => {
							this.closePay();
							this.payDone = true;
							this.confirm();
						}
					});
				} else {
					if (res.data?.query == 1) {
						this.payTimer = setTimeout(async () => {
							this.generalPaymentQuery(id);
						}, 1000)
					} else {
						loading.close()
						this.$message.warning(res.msg);
					}
				}

			},
			// 关闭支付弹窗并关闭支付事件
			closePay() {
				this.is_paying = false;
				this.scan.close()
				this.closePayDialog();

			},
			close() {
				this.$emit('update:showDialog', false);
			},
			//重置表单
			clear() {
				this.form = JSON.parse(JSON.stringify(emptyForm));
				this.sendData = {
					record_id: "", //	结案车辆id
					approver_name: "", //审批人名称
					delivery_reason: "", //出库原因
					approver_date: "", //审批日期
					case_remarks: "", //结案备注
					penalty_code: "", //处罚决定书编号
					attachment: [], //附件
					insurance_code: '',
				}
				this.$nextTick(() => {
					this.$refs["ruleForm"].clearValidate();
					this.fileList = [];
				})
			},
			async beforeRemove(file, fileList) {
				var result = await this.$confirm(`确定移除${file.name}？`);
				if (result == "confirm") {
					let res = await this.http.apiPost("/v1/Core/RecordClosedAttachmentDelete?url=" + file.url, null,
						true);
					if (res.flag) {
						return true;
					} else {
						return false
					}
				}
			},
			uploadSuccess(response, file, fileList) {
				if (response.flag) {
					this.fileList.unshift(response.data);
					if (!this.form.closed_attachment) {
						this.form.closed_attachment = [];
					}
					this.form.closed_attachment.unshift(response.data.url);
				} else {
					fileList = fileList.filter(v => v.uid != file.uid);
					this.fileList = fileList;
					this.$message.error(response.msg);
				}
			},
			async changeDate(date) {
				let res = await this.http.apiGet("v1/Core/ComputeAmount", {
					id: this.sendData.record_id,
					time: date
				}, true);
				if (res.flag) {
					this.amount = res.data;
				} else {
					this.$message.error(res.msg);
				}
			},
			confirm() {
				this.$refs["ruleForm"].validate(async (valid) => {
					if (valid) {
						if (this.amount > 0 && !this.payDone) {
							return this.showCollection = true;
						}
						this.sendData.attachment = this.fileList.map(v => v.url);
						for (let key in this.sendData) {
							if (!this.sendData[key]) {
								delete this.sendData[key];
							}
						};
						let res = await this.http.apiPost("v1/Core/RecordClosed", this.sendData, true);
						if (res.flag) {
							this.$notify({
								title: '温馨提示',
								message: '操作成功',
								type: 'success',
								duration: 1500,
							});
							this.$emit("update:showDialog", false);
							this.$emit("loadData")

						} else {
							this.$message.error(res.msg);
						}
						// this.isconfirm = true;
						// this.$emit("confirm", this.form);
					} else {
						return false;
					}
				});

			},
		}
	};
</script>
<style>
	.gridtable {
		color: #000000 !important;
	}
</style>
<style scoped>
	.form .el-form-item {
		margin-bottom: 0px !important;
	}

	.upload {
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		padding: 5px 15px;
	}
</style>
