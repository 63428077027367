<template>
	<div class="container">
		<div class="content">
			<div class="top">
				<el-menu default-active="1" class="el-menu-demo" mode="horizontal">
					<el-menu-item index="1">车辆管理</el-menu-item>
				</el-menu>
				<div class="back-btn" @click="backHome">
					<img src="../assets/images/u115.png">
					返回
				</div>
			</div>


			<el-row>
				<div class="seach-box">
					<ul class="nav-tabs">
						<li class="active"><span>车辆消息</span></li>
					</ul>
					<div class="tips">
						<div class="item" v-for="(item,index) in noticeList" :key="index">
							<el-row type="flex" align="middle">
								<i class="el-icon-message text-df " style="margin-right: 3px;" :style="item.is_view?'':'color:red'"></i>
								<span style="font-size: 14px;margin-right: 10px;" :style="item.is_view?'':'color:red'">{{item.is_view?"已读":"未读"}}</span>
								<span>{{item.content}}</span>
								<span class="link" @click="seeMessage(item)">查看</span>
							</el-row>
						</div>
						<div style="text-align: center;" v-if="noticeList.length==0">暂无消息..</div>
					</div>
					<el-row type="flex" align="middle" justify="end">
						<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background layout="total,prev, pager, next,jumper"
						 :total="pagination.total">
						</el-pagination>
					</el-row>
				</div>
			</el-row>
		</div>
	</div>
</template>
<script>
	import {
		Notification
	} from 'element-ui';
	import {
		mapState,
		mapMutations
	} from "vuex"
	export default {
		name: "message",
		data() {
			return {
				index: 0,
				noticeList: [],
				pagination: {
					index: 0,
					total: 0,
				}
			};
		},
		computed: {
			...mapState(["Notice", "notifyList"])
		},
		mounted() {
			this.notifyList.forEach(v => {
				v.close()
			})
			this.$store.commit("clearNotifyList");
			this.loadData()
		},
		created() {
			Notification.closeAll()
		},
		methods: {
			...mapMutations(["setmessageInfo"]),
			backHome() {
				this.$router.replace("/web/home")

			},
			async loadData() {
				let res = await this.http.apiGet("v1/Core/NoticeList", {
					index: this.index
				});
				if (res.flag) {
					this.noticeList = res.data.rows;
					this.pagination.total = res.data.total;
				} else {
					this.$message(res.msg);
				}
			},
			handleCurrentChange(val) {
				this.pagination.index = val;
				this.index = val - 1;
				this.loadData();
			},
			async seeMessage(item) {
				let res = await this.http.apiPost("v1/Core/ViewNotice", {
					notice_id: item.id
				})
				if (res.flag) {
					this.$router.push({
						name: 'supervise',
						params: {
							path: "processing"
						}
					})
					this.setmessageInfo(item);
					// this.$router.replace("/supervise/processing")
				}
			},
		}
	};
</script>

<style lang="scss" scoped>
	.container {
		height: 100%;

		.content {
			width: 100%;
		}

		.top {
			position: relative;
		}

		.line-box {
			width: 100%;
			height: 30px;
			background-color: #f5f5f5;
		}

		.back-btn {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 14px;
			width: 28px;
			height: 60px;
			position: absolute;
			right: 50px;
			top: 0;
			justify-content: center;
			cursor: pointer;

			img {
				width: 28px;
				height: 18px;
			}
		}

		.el-menu--horizontal>.el-menu-item {
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 16px;
			color: #999999;
			height: 50px;
			line-height: 50px;
			padding: 0 40px;
		}

		.el-menu-item.is-active {
			color: #409EFF !important;
		}

		.seach-box {
			margin: 30px auto;
			width: 95%;
			border-radius: 5px;
			border: 0.5px solid #CCCCCC;

			.tips {
				padding: 30px;

				.item {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					.link {
						text-decoration: underline;
						color: #409EFF !important;
						cursor: pointer;
						margin-left: 20px;
					}
				}
			}

			.nav-tabs {
				display: flex;
				list-style: none;
				border-bottom: 0.5px solid #CCCCCC;
				margin: 0;
				padding: 0;
				position: relative;

				.active {
					color: #4585EB !important;
				}

				li {
					padding: 14px 22px 7px 22px;
					font-family: 'Arial Normal', 'Arial';
					font-weight: 600;
					font-style: normal;

					font-size: 13px;
					color: #333333;
					text-align: center;
					line-height: normal;
					position: relative;

					&::after {
						content: "";
						width: 1px;
						height: 15px;
						background-color: #797979;
						position: absolute;
						right: 0;
						top: 15px;
					}

					span {
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
