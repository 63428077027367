<template>
	<div id="app">
		<router-view></router-view>
		<audio :muted="mutedBool" controls="controls" hidden src="./assets/voice.mp3" :loop="loop" ref="audio"></audio>
	</div>
</template>

// <script>
	// import Reg from "./components/Reg";
	// import Login from "./components/Login";
	import {
		Notification
	} from 'element-ui';
	import {
		mapMutations,
		mapState
	} from "vuex"
	export default {
		name: "app",
		data() {
			return {
				timer: null,
				audio: null,
				audioTimer: null,
				mutedBool: true,
				loop: true,
				isplay: false,
			}
		},
		watch: {
			isLogin: function(islogin) {
				if (islogin) {
					// this.getNewNotice();
				}
			}
		},
		created() {

			if (localStorage.getItem("token")) {
				// this.getNewNotice();
			}
			this.$store.dispatch("getSelectInfo");
		},
		computed: {
			...mapState(["isLogin"])
		},
		methods: {
			...mapMutations(["setNotice"]),
			async getNewNotice() {
				clearTimeout(this.timer)
				let res = await this.http.apiGet("v1/Core/Notice");
				if (res.flag && res.data) {
					this.setNotice(res.data)
					if (this.$refs.audio && res.data.is_sound) {
						this.loop = true;
						this.mutedBool = true;
						this.$refs.audio.currentTime = 0; //从头开始播放提示音
						this.$refs.audio.play(); //播放
						let messageStr = "";
						res.data.notice_content.forEach(v => {
							messageStr += `<p>${v}</p>`
						})
						Notification.closeAll();
						Notification({
							title: '请点击前往',
							dangerouslyUseHTMLString: true,
							message: messageStr,
							duration: 0,
							onClick: res => {
								this.loop = false;
								if (this.$route.path != "/web/message") this.$router.push("/web/message");
								else this.$router.go(0);

							},
							onClose: res => {
								this.loop = false
							}
						});
						// this.$store.commit("setNotifyList", notify); //增加到弹窗列表中
					}

				}
				this.timer = setTimeout(() => {
					this.getNewNotice()
				}, 3000)
			},
		}
	};
</script>

<style>
	/* @import '@/assets/app.css'; */

	html,
	body,
	#app {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.loding {
		z-index: 2099 !important;
	}

	#app {
		position: relative;
	}

	* {
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	.el-dialog {
		display: flex;
		flex-direction: column;
		margin: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		/*height:600px;*/
		max-height: calc(100% - 30px);
		max-width: calc(100% - 30px);
	}

	::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #CCCCCC;
		background-clip: padding-box;
		border-radius: 7px;
	}

	.el-dialog .el-dialog__body {
		flex: 1;
		overflow: auto;
	}
</style>
