<template>
	<div>
		<el-row>
			<el-col style="display:none" :span="18" id="hiprint-printTemplate" class="hiprint-printTemplate"></el-col>
			<el-col :span="6" style="display:none" class="params_setting_container">
				<el-row class="hinnn-layout-sider"><div id="PrintElementOptionSetting"></div></el-row>
			</el-col>
		</el-row>
		<el-dialog @opened="handlePreviewOpened" width="300px" title="打印二维码" :visible.sync="show" :before-close="closeDialog">
			<div class="qr-img-box" v-if="printData.qr_url">
				<img :src="printData.qr_url"/>
				<span class="margin-top-5">编码：{{ printData.description }}</span>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="closeDialog">取 消</el-button>
				<el-button type="primary" @click="printTemplate(printData)">打印</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import '@/assets/plugins/hiprint/css/hiprint.css';
import '@/assets/plugins/hiprint/css/print-lock.css';

import '@/assets/plugins/hiprint/polyfill.min.js';
import '@/assets/plugins/hiprint/plugins/jquery.minicolors.min';
import { hiprint } from '@/assets/plugins/hiprint/hiprint.bundle.js';
import '@/assets/plugins/hiprint/plugins/jquery.hiwprint.js';
import '@/assets/plugins/hiprint/plugins/qrcode.js';
import '@/assets/plugins/hiprint/plugins/JsBarcode.all.min.js';
import { defaultElementTypeProvider } from '@/assets/plugins/hiprint/etypes/default-etype-provider.js';
import { printData } from '@/assets/plugins/hiprint/custom_test/print-data.js';
var hiprintTemplate;
export default {
	name: 'qrcode',
	props: {
		show: false,
		// printData: null
		info: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			currentPaper: {
				width: null,
				height: null
			},
			json: {},
			printData: {
				qr_url: '',
				description: ''
			},
		};
	},
	mounted() {
		let self = this;

		$(document).ready(async function() {
			await self.getCusTomPrintJson();
			//初始化打印插件
			hiprint.init({
				providers: [new defaultElementTypeProvider()]
			});
			let _customPrintJson = self.json;
			hiprintTemplate = new hiprint.PrintTemplate({
				template: _customPrintJson,
				settingContainer: '#PrintElementOptionSetting',
				paginationContainer: '.hiprint-printPagination'
			});
			//打印设计
			hiprintTemplate.design('#hiprint-printTemplate');
		});
	},
	methods: {
		/**
		 * 获取打印配置
		 */
		async getCusTomPrintJson() {
			let res = await this.http.apiGet('v1/Core/PrintTmpl', null, true);
			if (res) {
				let qrcode = JSON.parse(res.qrcode);
				if (qrcode) this.json = qrcode;
			} else {
				this.$message.error('获取参数错误');
			}
		},
		/**
		 * @Author: TangLiangcheng
		 * @Date: 2019-07-30 11:21:20
		 * @Desc: 设置element click事件监听
		 */
		elementAddEventListen() {
			let self = this;
			window.hinnn.event.on(hiprintTemplate.getPrintElementSelectEventKey(), function(t) {
				self.paramsDrawerTitle = t.printElementType.title;
				self.paramsDrawerStatus = true;
			});
		},
		/**
		 * @Author: TangLiangcheng
		 * @Date: 2019-07-30 10:19:43
		 * @Desc: 设置纸张属性
		 * @param width 当前纸张宽度 mm
		 * @param height 当前纸张高度 mm
		 */
		setCurrentPaper(obj) {
			let { width, height } = obj;
			this.currentPaper.width = width;
			this.currentPaper.height = height;
			// hiprintTemplate.setPaper(width, height)
		},
		closeDialog() {
			this.$emit('update:show', false);
			this.clearPrintData();
		},
		/**
		 * @Author: TangLiangcheng
		 * @Date: 2019-07-30 10:46:07
		 * @Desc: 打印
		 */
		printTemplate(printData) {
			this.$emit('success');
			hiprintTemplate.print(printData);
		},
		//清空打印数据
		clearPrintData() {
			this.printData.qr_url= '';
			this.printData.description= '';
		},
		/**
		 * @Author: TangLiangcheng
		 * @Date: 2019-07-30 10:45:49
		 * @Desc: 打开预览dialog回调
		 */
		async handlePreviewOpened() {
			if (this.info?.code_url) {
				this.printData.qr_url = this.info.code_url;
				this.printData.description = this.info.print_code;
				return;
			}
			let res = await this.http.apiGet('v1/Core/GetCode', { id: this.info.id }, true);
			if (res.flag && res.data) {
				this.printData.qr_url = res.data.code_url;
				this.printData.description = res.data.print_code;
			} else {
				this.$message.error(res.msg);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.qr-img-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		width: 230px;
		height: 230px;
	}
}
.hiprint-printPaper {
	margin: 0 auto !important;
}
</style>
