import Vue from 'vue'
import Vuex from 'vuex'
import http from "../api/http.js"
import {
	Message
} from 'element-ui';
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		showLogin: false,
		isLogin: localStorage.getItem("token") ? true : false,
		token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
		userInfo: {},
		SelectInfo: {},
		Notice: {},
		messageInfo: {},
		notifyList: [],
		menuList: [],
	},
	getters: {
		Info: state => {
			return state.userInfo
		},
	},
	mutations: {
		//设置弹出登录框
		setShowLogin(state, data) {
			state.showLogin = data;
		},
		//登录
		Login(state, data) {
			if (data.token) {
				state.token = data.token;
				state.isLogin = true;
				state.userInfo = data.info;
				// processing
				if(data.menu){
					data.menu.push({
						display_no: 999,
						image: "",
						name: "车辆管理",
						route_address: "supervise",
					})
					state.menuList = data.menu;
				}
				
				localStorage.setItem("token", data.token);
				if (data.info) localStorage.setItem("userInfo", data.info);
			}
		},
		setNotifyList(state, data) {
			state.notifyList.push(data);
		},
		clearNotifyList(state) {
			state.notifyList = [];
		},
		setNotice(state, data) {
			if (data)
				state.Notice = data;
		},
		setmessageInfo(state, data) {
			if (data)
				state.messageInfo = data;
		},
		clearMessageInfo(state) {
			state.messageInfo = {}
		},
		Logout(state) {
			//清空所有登录信息
			localStorage.removeItem("token");
			localStorage.removeItem("userInfo");
			state.token = "";
			state.isLogin = false;
			state.userInfo = "{}";
		},
		setSelectInfo(state, data) {
			state.SelectInfo = data;
		}
	},
	actions: {
		async getUserInfo({
			commit,
			state
		}) {
			let res = await http.apiPost("v1/user/TokenLogin", {
				is_web: true
			})
			if (res.flag) {
				commit("Login", res.data)
				return res.data.menu;
			} else {
				commit("Logout")
				return false
			}
		},
		async getSelectInfo({
			commit,
			state
		}) {
			let res = await http.apiGet("v1/Core/CustomCode", null)
			if (res.flag) {
				commit("setSelectInfo", res.data)
			} else {
				Message.error(res.msg)
			}
		}
	},
	modules: {}
})
