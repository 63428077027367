var Scan = (function () {
    function Scan() {
        this.is_scan = false;
        this.code = '';
    }
    Scan.init = function () {
        var scan = new Scan();
        return scan;
    };
    Scan.prototype.resetScan = function () {
        this.is_scan = false;
        this.code = '';
    };
    Scan.prototype.discern = function (e) {
        var _this = this;
        if (this.timeReset)
            clearTimeout(this.timeReset);
        if (e.keyCode >= 48 && e.keyCode <= 57) {
            if (this.is_scan) {
                this.code += e.key;
            }
            else {
                this.code = e.key;
                this.is_scan = true;
            }
        }
        else if (e.keyCode == 13 && this.is_scan) {
            if (this.complete)
                this.complete(this.code);
            this.is_scan = false;
        }
        this.timeReset = setTimeout(function () { _this.resetScan(); }, 600);
        e.stopPropagation();
        e.preventDefault();
        return false;
    };
    Scan.prototype.close = function () {
        this.complete = null;
        $(window).off('keyup');
    };
    Scan.prototype.read = function (complete) {
        this.complete = complete;
        this.code = '';
        var scan = this;
        $(window).on('keyup', function (e) { return scan.discern(e); });
    };
    return Scan;
}());

export default {
	Scan
}
// (function () {
//     Mousetrap.bind('tab', function (e) { return false; });
// })();
