
module.exports = {
	data() {
		return {}
	},
	onLoad() {

	},
	methods: {
		// ...mapMutations(["setShowLogin", "Logout"]),
	
	}
}
