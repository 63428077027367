<template>
	<el-dialog :title="'编辑' + title" :visible.sync="show" @closed="close" width="990px" :before-close="closeDialog">
		<el-dialog append-to-body :visible.sync="dialogVisible">
			<img width="50%" alt="" />
			<el-image style="width: 100%" :src="dialogImageUrl" fit="fill"></el-image>
		</el-dialog>
		<el-dialog title="请输入退回备注" :visible.sync="centerDialogVisible" width="30%" center top="30vh" append-to-body>
			<el-form :model="returnData" size="mini" ref="formRoule">
				<el-form-item label="退回审核备注:" prop="auditRemarks"><el-input v-model="returnData.auditRemarks" autocomplete="off"></el-input></el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="recordClosedReturn(true)">确 定</el-button>
			</span>
		</el-dialog>
		<Print ref="Print"></Print>
		<div class="margin-bottom-20 u-text-right text-red font-18" v-if="form.edit_user">{{ form.edit_user }}</div>
		<el-form :model="form" size="mini" :rules="rules" ref="ruleForm" label-position="left" label-width="120px" class="form">
			<el-row type="flex" align="middle" justify="space-between">
				<el-col :span="7">
					<el-form-item label="车牌号:"><el-input v-model="form.car_no" placeholder="请输入车牌号"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="车架号:"><el-input v-model="form.frame_number" placeholder="请输入车架号"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="发动机号:" prop="engine_number"><el-input v-model="form.engine_number" placeholder="请输入发动机号"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between">
				<el-col :span="7">
					<el-form-item label="车身颜色:"><el-input v-model="form.color" placeholder="请输入车身颜色"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="品牌:">
						<el-select v-model="form.brand" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.brands" :key="item.name" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="车型:" prop="model">
						<el-select v-model="form.model" filterable placeholder="请选择车型">
							<el-option v-for="item in SelectInfo.model" :key="item.name" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between">
				<el-col :span="7">
					<el-form-item label="新旧程度:">
						<el-select v-model="form.newness" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.new_level" :key="item.name" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="7" v-if="caseType == 'type_accident'">
					<el-form-item label="车辆所有人:"><el-input v-model="form.car_owner" autocomplete="off"></el-input></el-form-item>
				</el-col>
				<el-col :span="7" v-if="caseType == 'type_accident'">
					<!-- 	<el-form-item label="交强险凭证号:" prop="insurance_code">
						<el-input v-model="form.insurance_code" placeholder="请输入交强险凭证号"></el-input>
					</el-form-item> -->
				</el-col>
			</el-row>
			<div v-if="caseType == 'type_accident'">
				<el-row type="flex" align="middle" justify="space-between">
					<el-col :span="7">
						<el-form-item label="事故类型:">
							<el-select v-model="form.accident_type" filterable allow-create placeholder="请选择/输入">
								<el-option v-for="item in SelectInfo.accident_type" :key="item.name" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="事故时间:" prop="accident_time">
							<!-- <span>{{form.accident_time}}</span> -->
							<el-date-picker
								style="width: 100%"
								value-format="yyyy-MM-dd HH:mm:ss"
								v-model="form.accident_time"
								type="datetime"
								placeholder="请选择事故时间"
							></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="事故地点:" prop="accident_location "><el-input v-model="form.accident_location" placeholder="请输入事故地点"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" justify="space-between">
					<el-col :span="7">
						<el-form-item label="扣押日期:" prop="seizure_date">
							<el-date-picker
								style="width: 100%"
								value-format="yyyy-MM-dd HH:mm:ss"
								v-model="form.seizure_date"
								type="date"
								placeholder="请选择扣押日期"
							></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="扣车民警:" prop="withhold_police_name">
							<el-select v-model="form.withhold_police_name" filterable allow-create placeholder="请选择/输入">
								<el-option v-for="item in SelectInfo.withhold_police" :key="item.name" :label="item.name" :value="item.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="7"></el-col>
				</el-row>
			</div>
			<el-row type="flex" align="middle" justify="space-between" v-if="caseType == 'type_illegal'">
				<el-col :span="7">
					<el-form-item label="车辆所有人:"><el-input v-model="form.car_owner" placeholder="请输入车辆所有人"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="违法时间:" prop="illegal_time">
						<el-date-picker
							style="width: 100%"
							value-format="yyyy-MM-dd HH:mm:ss"
							v-model="form.illegal_time"
							type="datetime"
							placeholder="请选择违法时间"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="违法地点:" prop="illegal_location"><el-input v-model="form.illegal_location" placeholder="请输入违法地点"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between" v-if="caseType == 'type_illegal'">
				<el-col :span="7"></el-col>
				<el-col :span="7">
					<el-form-item label="扣押日期:" prop="seizure_date">
						<el-date-picker
							style="width: 100%"
							value-format="yyyy-MM-dd HH:mm:ss"
							v-model="form.seizure_date"
							type="date"
							placeholder="请选择扣押日期"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="扣车民警:" prop="withhold_police_name">
						<el-select v-model="form.withhold_police_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.withhold_police" :key="item.name" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="7"></el-col>
			</el-row>

			<el-row type="flex" align="middle" justify="space-between" v-if="caseType == 'type_illegal'">
				<!-- 				<el-col :span="7">
					<el-form-item label="经办人:" >
						<el-select v-model="form.agent_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.agenter" :key="item.id" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col> -->
				<el-col :span="7">
					<el-form-item label="违法当事人:" prop="illegal_person"><el-input v-model="form.illegal_person" placeholder="违法当事人"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<!-- 	<el-form-item label="办案单位:">
						<el-select v-model="form.unit_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.unit" :key="item.ID" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item> -->
				</el-col>
				<el-col :span="7"></el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between" v-if="caseType == 'type_accident'">
				<el-col :span="7">
					<el-form-item label="事故当事人:" prop="accident_person"><el-input v-model="form.accident_person" placeholder="事故当事人"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<!-- 	<el-form-item label="办案单位:">
						<el-select v-model="form.unit_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.unit" :key="item.ID" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item> -->
				</el-col>
				<el-col :span="7"></el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between" v-if="caseType == 'type_case'">
				<el-col :span="7">
					<el-form-item label="车辆所有人:"><el-input v-model="form.car_owner" placeholder="请输入车辆所有人"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<div>
						<el-form-item label="扣押日期:" prop="seizure_date">
							<el-date-picker
								style="width:100%;"
								value-format="yyyy-MM-dd HH:mm:ss"
								v-model="form.seizure_date"
								type="date"
								placeholder="请选择扣押日期"
							></el-date-picker>
						</el-form-item>
					</div>
				</el-col>
				<el-col :span="7">
					<!-- <el-form-item label="办案单位:">
						<el-select v-model="form.unit_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.unit" :key="item.ID" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item> -->
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between" v-if="caseType == 'type_case'">
				<el-col :span="7">
					<el-form-item label="案件编号:" prop="case_code"><el-input v-model="form.case_code" placeholder="请输入案件编号"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="案件名称:" prop="case_name"><el-input v-model="form.case_name" placeholder="请输入案件名称"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="经办人:" v-if="caseType != 'type_illegal'">
						<el-select v-model="form.agent_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.agenter" :key="item.id" :label="item.name" :value="item.name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row type="flex" align="middle" justify="space-between">
				<el-col :span="7">
					<el-form-item label="入库时间:" prop="storage_time">
						<el-date-picker
							style="width: 100%"
							value-format="yyyy-MM-dd HH:mm:ss"
							v-model="form.storage_time"
							type="datetime"
							placeholder="请选择入库时间"
						></el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="7">
					<el-form-item label="接收人:"><el-input v-model="form.parking_receive_name" placeholder="请输入接收人"></el-input></el-form-item>
				</el-col>
				<el-col :span="7">
					<el-form-item label="电话:"><el-input v-model="form.parking_receive_phone" placeholder="请输入电话"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" justify="space-between">
				<el-col :span="7">
					<el-form-item label="停车场:" prop="parking_name">
						<el-select v-model="form.parking_name" filterable allow-create placeholder="请选择/输入">
							<el-option v-for="item in SelectInfo.park" :key="item.id" :label="item.parking_name" :value="item.parking_name"></el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="7">
					<el-form-item label="车位号:"><el-input v-model="form.parking_number" placeholder="车位号"></el-input></el-form-item>
				</el-col>
				<el-col :span="7"></el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="入场照片:">
						<el-image
							v-if="form.parking_place"
							style="width: 100px; height: 100px;margin-right: 10px;"
							:src="form.parking_place"
							fit="fill"
							:preview-src-list="[form.parking_place]"
							:z-index="9999"
						></el-image>
						<el-upload
							ref="place"
							accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PBG,.GIF"
							class="upload-demo"
							:on-success="updatePlaceSuccess"
							:show-file-list="false"
							:headers="http.headers"
							name="data"
							:action="http.url('/api/v1/Core/Upload')"
						>
							<el-button size="small" type="primary">{{ form.parking_place ? '重新上传' : '立即上传' }}</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="现场照片">
						<el-upload
							ref="upload1"
							v-if="showDialog"
							:file-list="fileList1"
							:action="http.url('/api/v1/Core/Upload')"
							:on-success="uploadSuccess1"
							:headers="http.headers"
							accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PBG,.GIF"
							name="data"
							list-type="picture-card"
						>
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)"><i class="el-icon-zoom-in"></i></span>
									<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)"><i class="el-icon-delete"></i></span>
								</span>
							</div>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="20">
					<el-form-item label="详细内容:"><el-input type="textarea" :rows="2" placeholder="请输入详细内容" v-model="form.details_content" /></el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="20">
					<el-form-item label="备注:"><el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="form.storage_remarks" /></el-form-item>
				</el-col>
			</el-row>
			<el-divider v-if="form.is_show_examine" content-position="center">结案情况</el-divider>
			<div v-if="form.is_show_examine">
				<el-row type="flex" align="middle" :gutter="20">
					<el-col :span="8">
						<el-form-item label="放行单编号:" prop="release_no"><el-input v-model="form.release_no"></el-input></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="放车单日期:" prop="release_date">
							<el-date-picker style="width: 100%;" disabled size="mini" v-model="form.release_date" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd">
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="车辆去向:" prop="gone_type">
							<el-select style="width: 100%;" v-model="form.gone_type" placeholder="请选择车辆去向">
								<el-option v-for="item in SelectInfo.gone_type" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" :gutter="20">
					<el-col :span="8">
						<el-form-item label="取车人:" prop="gone_type"><el-input v-model="form.take_name"></el-input></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="取车人电话:" prop="gone_type"><el-input v-model="form.take_phone"></el-input></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="取车人证件号:" prop="gone_type"><el-input v-model="form.license_no"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" :gutter="20" v-if="form.delivery_time">
					<el-col :span="8">
						<el-form-item label="出场时间:" prop="gone_type">
							<span>{{ form.delivery_time }}</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label="放车单照片：">
							<el-upload
								ref="upload2"
								class="upload-demo"
								:action="http.url('/api/v1/Core/Upload')"
								:on-success="uploadSuccess"
								list-type="picture-card"
								accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PBG,.GIF"
								:before-remove="beforeRemove"
								:headers="http.headers"
								multiple
								:file-list="fileList"
							>
								<i slot="default" class="el-icon-plus"></i>
								<div slot="file" slot-scope="{ file }">
									<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)"><i class="el-icon-zoom-in"></i></span>
										<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)"><i class="el-icon-delete"></i></span>
									</span>
								</div>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label="结案备注："><el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.case_remarks"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-divider v-if="form.record_id" content-position="center">超时费用</el-divider>
				<div class="text-red text-bold u-text-center font-26">{{ form.amount }}元</div>
			</div>
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="success" v-if="form.is_print" style="float: left;" @click="print">打印结案单</el-button>
			<el-button type="danger" v-if="form.is_wait_out_pending" style="float: left;" @click="centerDialogVisible = true">审核退回</el-button>
			<el-button type="success" v-if="form.is_wait_out_pending" style="float: left;" @click="recordClosedReturn(false)">审核通过</el-button>
			<el-button type="warning" v-if="form.is_trailer_btn" style="float: left;" @click="trailerCar">车辆放空</el-button>
			<el-button type="primary" @click="confirm">确 认</el-button>
			<el-button type="danger" @click="close">取 消</el-button>
		</div>
	</el-dialog>
</template>
<!-- case_status_code status_wait_out status_already_out -->
<script>
import Print from '@/components/Print.vue';
var emptyForm = {
	unit_name: '', //单位名称
	agent_name: '', //经办人姓名
	parking_receive_name: '', //停车场人员
	car_no: '', //车牌号
	frame_number: '', //	车架号
	engine_number: '', //发动机号
	seizure_date: '', //扣押日期
	car_owner: '', //车辆所有人
	brand: '', //品牌
	model: '', //车型
	color: '', //颜色
	newness: '', //新旧成都
	details_content: '', //详细内容
	id: '',
	storage_time: '', //入库时间
	park_name: '', //停车场名称
	parking_number: '', //停车场车位号
	case_name: '', //案件名称
	case_code: '', //案件编码
	parking_receive_phone: '' //停车场接收人电话
};
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'addDialog',
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},
		caseType: {
			type: String,
			default: 'type_case'
		},
		formData: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	components: {
		Print
	},
	data() {
		return {
			title: '',
			show: false,
			dialogImageUrl: '', //图片预览地址
			dialogVisible: false, //图片预览
			centerDialogVisible: false, //退回弹窗
			imageList: [],
			form: emptyForm,
			disabled: false,
			showClosed: false,
			returnData: {
				auditRemarks: ''
			}, //退回详情
			fileList: [], //结案附件
			fileList1: [], //车辆附件
			goneimage: [],
			rules: {
				seizure_date: [
					{
						required: true,
						message: '请选择或输入扣押时间',
						trigger: 'change'
					}
				],
				parking_name: [
					{
						required: true,
						message: '请选择或输入停车场',
						trigger: 'change'
					}
				],
				case_name: [
					{
						required: true,
						message: '请输入案件名称',
						trigger: 'blur'
					}
				],
				model: [
					{
						required: true,
						message: '请选择车型',
						trigger: 'blur'
					}
				],
				case_code: [
					{
						required: true,
						message: '请输入案件编号',
						trigger: 'blur'
					}
				],
				// insurance_code: [{
				// 	required: true,
				// 	message: "请输入交强险凭证号",
				// 	trigger: "blur",
				// }],
				action_code: [
					{
						required: true,
						message: '请输入强制编号',
						trigger: 'blur'
					}
				]
			},
			formLabelWidth: '100px',
			isconfirm: false //是否为请求接口
		};
	},
	computed: {
		...mapState(['SelectInfo']),
		...mapGetters({
			Info: 'Info'
		})
	},
	created() {
		switch (this.caseType) {
			case 'type_case':
				this.title = '涉案车辆';
				break;
			case 'type_accident':
				this.title = '事故车辆';
				break;
			case 'type_illegal':
				this.title = '违法车辆';
				break;
		}
	},
	watch: {
		caseType: function(val) {
			switch (val) {
				case 'type_case':
					this.title = '涉案车辆';
					break;
				case 'type_accident':
					this.title = '事故车辆';
					break;
				case 'type_illegal':
					this.title = '违法车辆';
					break;
			}
		},
		showDialog: function(val) {
			this.show = val;
			if (val) this.$store.dispatch('getSelectInfo');
			if (!val && !this.isconfirm) {
				this.clear();
			}
		}
	},
	methods: {
		//解决图片预览关闭弹窗
		closeDialog(done) {
			const a = document.getElementsByClassName('el-image-viewer__wrapper')[0];
			if (!a) {
				done();
			}
		},
		parkChange(name) {
			var item = this.SelectInfo.park.find(v => v.parking_name == name);
			if (item) {
				this.form.parking_no = item.parking_no;
			} else {
				this.form.parking_no = '';
			}
		},
		parkNoChange(parking_no) {
			var item = this.SelectInfo.park.find(v => v.parking_no == parking_no);
			if (item) {
				this.form.parking_name = item.parking_name;
			}
		},
		async setFormData(data) {
			let res = await this.http.apiGet(
				'v1/Core/RecordClosed',
				{
					recordId: data.id
				},
				true
			);
			if (res.flag) {
				var form = {};
				form = res.data.record;
				this.fileList1 = res.data.recordimage;
				this.fileList = res.data.goneimage;

				if (res.data.closed) {
					this.showClosed = true;
					var obj = res.data.closed;
					for (let key in obj) {
						form[key] = obj[key];
					}
				} else {
					this.showClosed = false;
				}
				this.form = form;
				this.form.gone_attachment = res.data.goneimage.map(v => v.url);
			} else {
				this.$message.error(res.msg);
			}
		},
		close() {
			this.$emit('update:showDialog', false);
		},
		//重置表单
		clear() {
			this.form = emptyForm;
			this.fileList = [];
			this.fileList1 = [];
			this.returnData.auditRemarks = '';
			this.$nextTick(() => {
				this.$refs['ruleForm'].clearValidate();
			});
		},
		async beforeRemove(file, fileList) {
			var result = await this.$confirm(`确定移除 ${file.name}？`);
			if (result == 'confirm') {
				var list = fileList.filter(v => v.uid != file.uid);
				this.form.gone_attachment = list.map(v => {
					v = v.url;
					return v;
				});
				return true;
			} else {
				return false;
			}
		},
		uploadSuccess(response, file, fileList) {
			if (response.flag) {
				this.fileList.unshift(response.data);
				if (!this.form.gone_attachment) {
					this.form.gone_attachment = [];
				}
				this.form.gone_attachment.unshift(response.data.url);
			} else {
				fileList = fileList.filter(v => v.uid != file.uid);
				this.fileList = fileList;
				this.$message.error(response.msg);
			}
		},
		updatePlaceSuccess(response, file, fileList) {
			if (response.flag) {
				this.form.parking_place = response.data.url;
			} else {
				this.$message.error(response.msg);
			}
			this.$refs.place.clearFiles();
		},
		uploadSuccess1(response, file, fileList) {
			if (response.flag) {
				this.fileList1.unshift(response.data);
			} else {
				this.$message.error(response.msg);
			}
		},
		//图片预览
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		//删除图片
		async handleRemove(file, fileList) {
			this.fileList1 = this.fileList1.filter(v => v.uid != file.uid);
			this.fileList = this.fileList.filter(v => v.uid != file.uid);
		},
		async recordClosedReturn(is_retrun) {
			this.returnData.recordId = this.form.id;
			this.returnData.is_success = !is_retrun;
			if (is_retrun && !this.returnData.auditRemarks) {
				return this.$message.warning('请输入退回原因');
			}
			let res = await this.http.apiPost('v1/core/RecordClosedReturn', this.returnData, true);
			if (res.flag) {
				this.$emit('update:showDialog', false);
				this.clear();
				this.centerDialogVisible = false;
				this.$notify({
					title: '温馨提示',
					message: '操作成功',
					type: 'success',
					duration: 1500
				});
				this.$emit('loadData');
			} else {
				this.$message.error(res.msg);
			}
		},
		/**
		 * 放空车辆
		 */
		async trailerCar() {
			const result = await this.$confirm(`确定要放空车辆吗？`,'温馨提示');
			if (result == 'confirm') {
				let res = await this.http.apiGet('v1/core/CarVent', { recordId: this.form.id }, true);
				if (res.flag) {
					this.$notify({
						title: '温馨提示',
						message: '操作成功',
						type: 'success',
						duration: 1500
					});

					this.$emit('loadData');
					this.close();
				} else {
					this.$message(res.msg);
				}
			}
		},
		//开启打印
		print() {
			this.$refs.Print.beganPrint(this.form.id);
		},
		//确认编辑
		confirm() {
			this.$refs['ruleForm'].validate(async valid => {
				if (valid) {
					this.form.record_attachment = this.fileList1.map(v => {
						v = v.url;
						return v;
					});
					let res = await this.http.apiPost('v1/Core/CarEdit', this.form, true);
					if (res.flag) {
						this.$notify({
							title: '温馨提示',
							message: '操作成功',
							type: 'success',
							duration: 1500
						});

						this.$emit('loadData');
						this.close();
					} else {
						this.$message(res.msg);
					}
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style scoped>
.upload {
	border: 1px solid #dcdfe6;
	border-radius: 4px;
	padding: 5px 15px;
}

.preview {
	display: flex;
	align-items: center;
}

.image-preview {
	border-radius: 5px;
	border: 1px solid #ddd;
	padding: 15px;
	margin-right: 15px;
}
</style>
