/**
 * 打印页面数据
 * @param {String} content 
 * @param {*} w 
 * @param {*} h 
 */
export function print(content, w = null, h = null) {
	// Fixes dual-screen position                         Most browsers      Firefox
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

	const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth :
		screen.width;
	const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement
		.clientHeight : screen.height;
	w = +w === 0 ? width : w;
	h = +h === 0 ? height : h;
	const left = ((width / 2) - (w / 2)) + dualScreenLeft;
	const top = ((height / 2) - (h / 2)) + dualScreenTop;
	var myWindow = window.open("", "打印",
		"toolbar=no, location=no, directories=no, status=no,  =no, scrollbars=no, resizable=yes, copyhistory=no, width=" +
		w + ", height=" + h + ", top=" + top + ", left=" + left);
	var style =
		"<style type='text/css'>.defaut{margin-top:10px !important} .block{margin-top:30px} table.gridtable {margin:0 auto 30px;font-family: verdana,arial,sans-serif;font-size:11px;color:#333333;border-width: 1px;border-color: #666666;border-collapse: collapse;}table.gridtable th {border-width: 1px;padding: 8px;border-style: solid;min-width:100px;text-align: start;border-color: #666666;background-color: #dedede;}table.gridtable td {border-width: 1px;padding: 8px;border-style: solid;border-color: #666666;background-color: #ffffff;}  .item_td{width:200px !important;}.bottom{display: flex;justify-content: space-around;font-size: 11px;color: #333333; }</style>";
	myWindow.document.write(content + style);
	myWindow.focus();
	myWindow.document.close(); //关闭document的输出流, 显示选定的数据
	myWindow.print(); //打印当前窗口
	return myWindow;
}
