<template>
	<div class="content-container">

		<div class="content">
			<div class="top">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
					<el-menu-item index="dispatch" v-if="showDispatch('dispatch')">派单列表</el-menu-item>
					<el-menu-item index="processing" v-if="showDispatch('processing')">车辆管理</el-menu-item>
					<el-menu-item index="carStatistics" v-if="showDispatch('carStatistics')">车辆统计</el-menu-item>
					<el-menu-item index="reportform" v-if="showDispatch('reportform')">费用统计</el-menu-item>
					<el-menu-item :index="activeIndex=='editcar'?'editcar':'notice'" v-if="showDispatch('notice')">公告管理</el-menu-item>
					<el-menu-item index="remind" v-if="showDispatch('remind')">提醒设置</el-menu-item>

				</el-menu>
				<div class="back-btn" @click="backHome">
					<img src="../../assets/images/u115.png">
					返回
				</div>
			</div>

			<div class="router-content">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>
	import {
		log
	} from 'util';
	import {
		mapState,
	} from "vuex"
	export default {
		data() {
			return {
				activeIndex: 'dispatch',
			};
		},
		computed:{
			...mapState(['menuList']),
			
		},
		mounted() {
			let activeIndex = localStorage.getItem("activeIndex");
			let params = this.$route.name;
			if (activeIndex && !params) {
				this.activeIndex = activeIndex
			} else {
				this.activeIndex = params
			}
			localStorage.setItem("activeIndex", this.activeIndex);
			let nowPath = "/supervise/" + this.activeIndex
			if (nowPath !== this.$route.path) {
				this.$router.replace(this.activeIndex)
			}
		},
		methods: {
			showDispatch(name) {
				if (this.menuList?.length==0) return false
				return this.menuList.some(v => v.route_address == name);
			},
			backHome() {
				if (this.$route.path == "/supervise/EditCar") {
					this.$router.replace("notice")
				} else if (this.$route.path == '/supervise/addDispatch') {
					this.$router.replace("dispatch")
				} else {
					this.$router.replace("/web/home")
				}

			},
			handleSelect(key) {
				if (this.activeIndex === key) return;
				this.activeIndex = key;
				this.$router.replace(key);
				localStorage.setItem("activeIndex", this.activeIndex);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.content-container {
		height: 100%;
		.content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;

			.router-content {
				flex: 1;
				min-height: 500px;
			}
		}

		.top {
			position: relative;
		}

		.line-box {
			width: 100%;
			height: 30px;
			background-color: #f5f5f5;
		}

		.back-btn {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 14px;
			width: 28px;
			height: 60px;
			position: absolute;
			right: 50px;
			top: 0;
			justify-content: center;
			cursor: pointer;

			img {
				width: 28px;
				height: 18px;
			}
		}

		.el-menu--horizontal>.el-menu-item {
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 16px;
			color: #999999;
			height: 50px;
			line-height: 50px;
			padding: 0 20px;
		}

		.el-menu-item.is-active {
			border-bottom-width: 3px;
			color: #4585EB !important;
		}
	}
</style>
