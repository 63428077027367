<template>

	<el-container class="main-container">
		<el-row class="header " :class="{'fit-header':isHome}" type="flex" align="middle" justify="space-between">
			<img src="../assets/images/home.png" class="header-bg" alt="">
			<div class="logo-content" @click="navToHome">
				<img class="logo" src="../assets/images/logo.png">
				<span class="name ">涉案车辆停放管理</span>
			</div>
			<div class="right">
				<div class="top">
					<div class="right-top">
						<img src="../assets/images/u10.png" class="icon">
						<span>设置</span>
					</div>
					<div class="right-top">
						<img src="../assets/images/u6.png" class="icon">
						<span>帮助</span>
					</div>
				</div>
				<div class="user" @click="login" v-if="!isLogin">
					<img src="../assets/images/u4.png">
					<span class="Medium">登录</span>
				</div>
				<div class="user-login" v-else>
					<div @click="showUserInfo=true" style="display: flex; align-items: center;">
						<img :src="Info.face" v-if="Info.face">
						<img src="../assets/images/u4.png" v-else>
						<span style="margin-left: 10px;">欢迎,{{Info.name}}</span>
					</div>
					<span style="margin: 0  10px;">|</span>
					<span class="lagout" @click="lagoutSystem">退出</span>
				</div>
			</div>
		</el-row>
		<el-main style="padding: 0;margin-bottom: 50px;">
				<router-view />
		</el-main>
		<el-footer height="50px" style="background-color: #f1f1f1; position: fixed; bottom: 0;width: 100%;z-index: 99;">
			<div class="footer">由恒智百川提供技术支持</div>
		</el-footer>
		<el-dialog width="600px" title="修改用户资料" :visible.sync="showUserInfo">
			<el-form style="width: 50%;" size="small" ref="userForm" :rules="rules" :model="form" label-width="auto">
				<el-form-item label="用户名">
					<el-input v-model="form.name" autocomplete="off"></el-input>
					<el-button type="text" @click="showEditPass = !showEditPass">{{showEditPass?'取消修改':"修改用户密码"}}</el-button>
				</el-form-item>
				<el-form-item label="接收公众号消息">
					<el-switch v-model="form.is_receive_notice" active-color="#13ce66" inactive-color="#ff4949">
					</el-switch>
				</el-form-item>
				<template v-if="showEditPass">
					<el-form-item label="原密码" prop="old_pwd">
						<el-input type="password" v-model.trim="form.old_pwd" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="new_pwd1">
						<el-input type="password" v-model.trim="form.new_pwd1" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="new_pwd2">
						<el-input type="password" v-model.trim="form.new_pwd2" autocomplete="off"></el-input>
					</el-form-item>
				</template>
				<el-form-item label="绑定微信" prop="">
					<el-button type="text" @click="getQcCode">获取最新二维码</el-button>
					<el-image v-if="qcode" style="width: 200px; height: 200px" :src="qcode"></el-image>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="showUserInfo = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>

<script>
	import {
		mapMutations,
		mapState,
		mapGetters
	} from "vuex"

	import 'element-ui/lib/theme-chalk/display.css';
	export default {

		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.form.new_pwd1) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				showUserInfo: false,
				showEditPass: false, //修改密码
				qcode: "", //二维码

				form: {
					name: "",
					old_pwd: "",
					new_pwd1: "",
					new_pwd2: "",
					is_receive_notice: false
				},
				rules: {
					old_pwd: [{
							required: true,
							message: '请输入旧密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'change'
						}
					],
					new_pwd1: [{
							required: true,
							message: '请设置新登录密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'change'
						}
					],
					new_pwd2: [{
							required: true,
							message: '请确认登录密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'change'
						},
						{
							validator: validatePass,
							trigger: 'change'
						}
					],
				},
			}
		},
		watch: {
			showUserInfo: function(val) {
				if (!val) {
					this.$refs.userForm.resetFields()
				}
			}
		},
		computed: {
			...mapState(["isLogin", "userInfo"]),
			...mapGetters({
				Info: "Info",
			}),
			isHome(){
				if(this.$route.name!='home') return true;
				return false;
			},
		},
		mounted() {
			this.form.name = this.Info.name
			this.form.is_receive_notice = this.Info.is_receive_notice;
			eventBus.$on('showInfo',res=>{
				this.showUserInfo=!this.showUserInfo;
			})
		},
		methods: {
			...mapMutations(["setShowLogin", "Logout", "Login"]),
			login() {
				eventBus.$emit('changeLogin');
			},
			navToHome(){
				if(this.$route.name!='home')
				this.$router.replace({name:"home"});
			},
			async getQcCode() {
				let res = await this.http.apiGet("v1/user/BindWechat", null, true);
				if (res.flag) {
					this.$message({
						type: 'success',
						message: '获取成功，请使用微信扫描二维码！',
					});
					this.qcode = res.data.src;
				} else {
					this.$message(res.msg)
				}
			},
			submitForm() {
				this.$refs["userForm"].validate(async (valid) => {
					if (valid) {
						var sendData = {
							name: this.form.name
						}
						if (this.showEditPass) {
							sendData.old_pwd = this.http.md5(this.form.old_pwd)
							sendData.new_pwd = this.http.md5(this.form.new_pwd1)
						}
						let res = await this.http.apiPost("v1/user/UpdateInfo", sendData, true);
						if (res.flag) {
							this.Login(res.data);
							this.$message({
								type: 'success',
								message: '修改成功',
							});
							this.showUserInfo = false
						} else {
							this.$message(res.msg)
						}


					} else {

						return false;
					}
				});
			},
			lagoutSystem() {
				this.$confirm('是否退出当前账号?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.Logout();
					this.$message({
						type: 'success',
						message: '已成功退出！'
					});
					this.$router.replace({
						name:"home"
					})
				}).catch(() => {

				});
			},
		}
	};
</script>

<style scoped lang="scss">
	.main-container{
		height: 100vh;
		display: flex;
		flex-direction: column;
		background-color: #FFFFFF;
	}
	.el-header {
		background-color: #b3c0d1;
		color: #333;
	}

	.el-aside {
		color: #333;
	}

	.header {
		height: 147px;
		position: relative;
		.header-bg{
			width: 100%;
			height: 600px;
			position: absolute;
			left: 0;
			top: 0;
			object-fit:cover;
		}
	}
	.fit-header{
		overflow: hidden;
	}

	.user {
		height: 110px;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
	}

	.user-login {
		display: flex;
		cursor: pointer;
		align-items: center;
		font-size: 16px;
		color: #FFFFFF;
		height: 80px;
	}

	.user-login img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.footer {
		color: #999;
		font-size: 14px;
		text-align: center;
		height: 50px;
		line-height: 50px;

	}

	.lagout:hover {
		text-decoration: underline;
		color: #E6A23C;
	}

	.user>span {
		color: #FFFFFF;
		margin-top: 5px;
		font-size: 17px;
	}

	.user>img {
		width: 48px;
		height: 48px;
		border-radius: 50%;
	}

	.right {
		height: 100%;
		display: flex;
		flex-direction: column;
		margin-right: 50px;
			position: relative;
		z-index: 10;
	}

	.top {
		width: 130px;
		display: flex;
		margin-top: 15px;
		justify-content: space-between;
		color: #FFFFFF;
		margin-bottom: 15px;
	}

	.right-top {
		display: flex;
		color: #fff;
		text-decoration: none;
		align-items: center;
		cursor:pointer
	}

	.icon {
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}

	.logo-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 10;
		margin-left: 30px;
		cursor: pointer;
	}

	.logo {
		width: 304px;
		height: 35px;
	}

	.name {
		padding-left: 42px;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 1.5rem;
		letter-spacing: 2px;
		margin-top: 8px;
		color: #FFFFFF;
		white-space: nowrap;
	}
</style>
