<template>
	<div class="">
		<el-dialog title="车辆明细" :visible.sync="show" @open="open" :before-close="close" append-to-body width="1300px">
			<el-row type="flex" justify="end" style="margin-bottom: 10px">
				<el-button type="primary" class="margin-left-20 " size="small" @click="outerExcel">导出Excel</el-button>
			</el-row>
			<el-table :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}" border
				v-loading="loading" id="exportTab" :data="tableList" style="width: 100%">
				<el-table-column fixed prop="car_no" label="车牌号" class-name="nopadding">
					<template slot-scope="scope">
						<p class="table-number">{{ scope.row.car_no }}</p>
					</template>
				</el-table-column>
				<el-table-column label="车架号/发动机号" align="center" min-width="120" lass-name="nopadding">
					<template slot-scope="scope">
						<p class="table-number">{{ scope.row.frame_number }}</p>
						<p class="table-number">{{ scope.row.engine_number }}</p>
					</template>
				</el-table-column>
				<el-table-column prop="brand" label="品牌" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="color" label="颜色" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="storage_time" align="center" min-width="120" label="入场时间" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="day" align="center" label="停放天数" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="overtime_day" align="center" label="超时天数" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="amount" align="center" label="超时停车费(元)" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="pay_name" align="center" label="支付方式" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="delivery_time" align="center" min-width="120" label="出场时间"
					class-name="nopadding">
				</el-table-column>
				<el-table-column prop="release_date" align="center" min-width="120" label="放车单时间"
					class-name="nopadding">
				</el-table-column>
				<el-table-column prop="work_name" label="施救人" class-name="nopadding">
				</el-table-column>
				<el-table-column prop="verify_name" label="出场审核人" class-name="nopadding">
				</el-table-column>
			</el-table>
			<el-row type="flex" align="middle" justify="end" class="margin-top-30">
				<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background
					:page-size="10" layout="total,prev, pager, next,jumper" :total="pagination.total">
				</el-pagination>
			</el-row>
			<div slot="footer">
				<el-button type="danger" size="medium" @click="close">关 闭</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		name: 'carList',
		props: {
			//是否展示弹窗
			show: {
				type: Boolean,
				default: false
			},
			//弹窗请求参数
			editSendData: {
				type: Object,
				default: () => {
					return {};
				}
			}
		},

		data() {
			return {
				pagination: {
					index: 1,
					total: 0,
				},
				tableList: [],
				loading: false,
				exporturl: '',
			}
		},
		methods: {
			close() {
				this.$emit("update:show", false);
			},
			open() {
				this.pagination.index = 1;
				this.getCarList();
			},
			outerExcel() {
				window.open(this.exporturl);
			},
			//页数变化
			handleCurrentChange(val) {
				this.pagination.index = val;
				this.getCarList();
			},
			async getCarList() {
				const sendData = {
					...this.editSendData
				};
				sendData.index = this.pagination.index - 1;
				let res = await this.http.apiPost('v1/core/CarRecordList', sendData, true);
				if (res.flag) {
					this.pagination.total = res.data.list.total;
					this.tableList = res.data.list.rows;
					this.exporturl = res.data.exporturl;
				} else {
					this.$message.warning(res.msg);
				}
			},
		}
	}
</script>

<style>
</style>
