<template>
	<div class="content-box">
		<div class="seach-box">
			<ul class="nav-tabs">
				<li @click="cheackedType(item)" :class="{active:item.value==sendData.type}" v-for="(item) in typeList"
					:key="item.name"><span>{{item.name}}</span></li>
			</ul>
			<div class="form-box">
				<el-form :model="formData" ref="form" label-width="100px" size="mini">
					<el-row type="flex" :gutter="10">
						<el-col :span="4">
							<el-form-item label="车牌号:" prop="car_no">
								<el-input v-model.trim="formData.car_no" placeholder="请输入车牌号"></el-input>
							</el-form-item>
							<el-form-item label="车架号:" prop="frame_number">
								<el-input v-model.trim="formData.frame_number" placeholder="请输入车架号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="公告批次号:" prop="batch_no">
								<el-input v-model.trim="formData.batch_no" placeholder="请输入公告批次号"></el-input>
							</el-form-item>
							<el-form-item label="备注信息:" prop="remarks">
								<el-input v-model.trim="formData.remarks" placeholder="请输入备注信息"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">

							<el-form-item label="入库日期:" prop="storage_time">
								<el-date-picker value-format="yyyy-MM-dd" v-model.trim="formData.storage_time"
									type="daterange" align="right" unlink-panels range-separator="至"
									start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="search-btn-box">
						<el-button type="primary" size="mini" style="width: 100px;" @click="loadData">搜索</el-button>
						<el-button type="text" icon="el-icon-remove-outline" @click="clearFormData">重置搜索条件</el-button>
						<el-button type="text" icon="el-icon-caret-bottom">更多搜索条件</el-button>
						<!-- <i class=""></i> -->
					</div>
				</el-form>
			</div>
		</div>

		<!-- //表格 -->
		<el-row type="flex" class="edit-btn-box" align="middle" justify="space-between" style="margin-top: 18px;">
			<el-col :span="18">
				<el-button type="primary" icon="el-icon-plus" @click="handleAdd" size="small">新增</el-button>
				<el-button type="danger" icon="el-icon-close" size="small" :disabled="multipleSelection.length==0"
					@click="deletTableList(false)">删除选中</el-button>
			</el-col>
			<el-col :span="2">

				<el-col :span="2">
					<el-button type="primary" icon="el-icon-download" size="small" @click="getBulletinBatchExport">导出Excel</el-button>
				</el-col>
			</el-col>
		</el-row>
		<div style="height:20px"></div>
		<el-table :header-cell-style="{backgroundColor:'#EFEFEF',textAlign:'center',color:'#333'}" border
			v-loading="loading" :data="tableList" style="width: 100%" 
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="45" class-name="nopadding">
			</el-table-column>
			<el-table-column fixed prop="batch_no" label="公告批次号" align="center" class-name="nopadding">
				<template slot-scope="scope">
					<p class="table-number" @click="handleCar(scope.$index, scope.row,false)">{{ scope.row.batch_no }}
					</p>
				</template>
			</el-table-column>
			<el-table-column prop="begin_time" label="公告开始" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="end_time" label="公告结束" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="name" label="创建人" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="car_num" label="车辆总数" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="deal_num" label="已处理车辆数量" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column prop="status" label="批次状态" align="center" class-name="nopadding">
			</el-table-column>
			<el-table-column label="附件" align="center" class-name="nopadding">
				<template slot-scope="scope">
					<el-row type="flex" align="middle" justify="center">
						<span v-if="scope.row.is_exit_attachment.length>0" style="color:#3399FF;cursor:pointer"
							@click="handlePreview(scope.row.is_exit_attachment)">查看</span>
						<span v-else>暂无</span>
						<el-upload class="upload-demo"
							:action="http.url('/api/v1/Core/BulletinAttachmentAdd/'+scope.row.id)"
							:on-success="uploadSuccess" :headers="http.headers" name="data" :show-file-list="false">
							/
							<span style="color:#3399FF;cursor:pointer">增加</span>
						</el-upload>
					</el-row>
				</template>
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="300" class-name="nopadding">
				<template slot-scope="scope">

					<el-button size="mini" type="primary" @click="handleCar(scope.$index, scope.row,true)"
						v-if="scope.row.status=='公告到期'">处理车辆</el-button>
					<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination @current-change="handleCurrentChange" :current-page="pagination.index" background
				layout="total,prev, pager, next,jumper" :total="pagination.total">
			</el-pagination>
		</div>
		<!-- 编辑框 -->
		<el-dialog title="公告批次信息" :visible.sync="dialogFormVisible" width="350px" top="30vh">
			<el-row align="middle" type="flex" justify="center">
				<el-col :span="20">
					<el-form ref="sendForm" :model="form" label-width="80px" size="mini" :rules="rules">
						<el-form-item prop="batch_no" label="批次号">
							<el-input v-model="form.batch_no" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item prop="begin_time" label="公告开始">
							<el-date-picker value-format="yyyy-MM-dd" style="width: 100%;" v-model="form.begin_time"
								type="date" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item prop="end_time" label="公告结束">
							<el-date-picker value-format="yyyy-MM-dd" style="width: 100%;" v-model="form.end_time"
								type="date" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="form.remarks" autocomplete="off"></el-input>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer">
				<el-button size="medium" type="primary" @click="confirmEdit">确 定</el-button>
				<el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<AddNotice ref="AddNoticedialog" @loadData="loadData" :showDialog.sync="showAddDialog"></AddNotice>
		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="url" />
	</div>
</template>

<script>
	import AddNotice from "./components/addNotice.vue";
	// 导入组件
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	export default {
		components: {
			AddNotice,
			ElImageViewer
		},
		data() {
			return {
				showAddDialog: false, //新增弹出框
				dialogFormVisible: false, //编辑弹出框
				activeName: 'second',
				typeList: [{
						name: "全部批次",
						value: "",
					},
					{
						name: "公告中",
						value: "bulletin_processing",
					},
					{
						name: "公告到期",
						value: "bulletin_end",
					}
				],
				sendData: {
					type: "",
					time: "",
				},
				loading: false,
				showViewer: false, // 显示查看器
				url: [], //当前图片地址;
				rules: {
					batch_no: [{
						required: true,
						message: '请输入批次号',
						trigger: 'blur'
					}],
					begin_time: [{
						required: true,
						message: '请选择开始时间',
						trigger: 'change'
					}],
					end_time: [{
						required: true,
						message: '请选择结束时间',
						trigger: 'change'
					}],
				}, //验证规则
				form: {
					batch_no: "",
					begin_time: "",
					end_time: "",
					remarks: ""
				},
				multipleSelection: [], //表格选中id数组集合
				formData: {
					car_no: "",
					frame_number: "",
					batch_no: "",
					remarks: "",
					storage_time: "",
					begin_time: "",
					end_time: "",
					bulletin_status: "",
					index: 0,
				}, //搜索条件
				pagination: {
					index: 0,
					total: 0,
				}, //翻页数据
				tableList: [],
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				ExcelData: "", //默认分页导出表格
				ExcelData1: {},

			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			cheackedType(item) {
				this.sendData.type = item.value;
				this.formData.bulletin_status = item.value;
				this.loadData();
			},
			handleAdd() {
				this.showAddDialog = true;
				this.$refs.AddNoticedialog.getList();
			},
			//重置搜索条件
			clearFormData() {
				this.$refs["form"].resetFields();
			},
			handleDelete(index, row) {
				this.deletTableList([row.id]);
			},
			// 关闭查看器
			closeViewer() {
				this.showViewer = false
			},
			getBulletinBatchExport(sendData) {
				window.open(this.ExcelData);
			},

			/**
			 * 编辑确认修改
			 */
			async confirmEdit() {
				let res = await this.http.apiPost("v1/Core/BulletinBatchEdit", this.form, true);
				if (res.flag) {
					this.dialogFormVisible = false;
					this.$notify({
						title: '温馨提示',
						message: '修改成功',
						type: 'success',
						duration: 1500,
					});
					this.loadData();
				} else {
					this.$message(res.msg);
				}
			},
			/**
			 * 处理车辆
			 */
			handleCar(index, row, showEdit) {
				if (row.status == '公告到期') {
					showEdit = true
				} else {
					showEdit = false
				}
				localStorage.setItem("activeIndex", "EditCar");
				this.$router.replace({
					path: `EditCar`,
					query: {
						id: row.id,
						showEdit: showEdit ? showEdit : '',
					}
				})
			},
			/**
			 * 编辑
			 */
			handleEdit(index, row) {
				this.form = row;
				this.dialogFormVisible = true;
			},
			/**
			 * 预览文件
			 */
			handlePreview(file) {
				this.showViewer = true;
				this.url = file.map(v => v.image);
			},
			//判断类型为图片的方法
			compareImg(type) { //比较图片类型
				let typsList = ['bmp', 'jpg', 'png', 'gif', 'svg', 'webp'];
				if (typsList.indexOf(type) > -1) { //说明类型是图片
					return true;
				} else {
					return false;
				}
			},
			/**
			 * 上传成功回调
			 */
			uploadSuccess(response, file, fileList) {
				if (response.flag) {
					this.$notify({
						title: '温馨提示',
						message: '上传成功',
						type: 'success',
						duration: 1500,
					});
					this.loadData();
				} else {
					this.$message(res.msg);
				}
			},
			/**
			 * 获取表格数据
			 */
			async loadData() {
				this.loading = true
				var sendData = this.formData;
				if (this.formData.storage_time.length == 2) {
					sendData.begin_time = this.formData.storage_time[0];
					sendData.end_time = this.formData.storage_time[1];
				} else {
					sendData.begin_time = "";
					sendData.end_time = "";
				}
				sendData.index = this.formData.index;
				var res = await this.http.apiPost("v1/Core/BulletinBatchPage", sendData);
				this.loading = false
				if (res.flag) {
					let data = res.data.pager;
					this.pagination.total = data.total;
					this.tableList = data.rows;
					this.ExcelData = res.data.url;
				} else {
					this.$message(res.msg);
				}

			},
			//表格选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val.map(v => {
					v = v.id;
					return v;
				})
			},
			/**
			 * @param {Object} list id集合
			 * 单个以及多条删除表格列表数据
			 */
			deletTableList(list) {
				//不传参数则为批量删除，取multipleSelection  中的id集合
				if (!list) list = this.multipleSelection;
				this.$confirm('此操作会永久删除选中数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await this.http.apiPost("v1/Core/BulletinBatchDelete", {
						list
					});
					if (res.flag) {
						this.$notify({
							title: '温馨提示',
							message: '删除成功',
							type: 'success',
							duration: 1500,
						});
						this.loadData();
					} else {
						this.$message(res.msg)
					}
				})

			},
			//页数变化
			handleCurrentChange(val) {
				this.pagination.index = val;
				this.formData.index = val - 1;
				this.loadData();
				this.loadData();
			},
		}
	};
</script>

<style>
	.nopadding {
		padding: 8px 0 !important;
	}
</style>
<style lang="scss">
	.el-icon-circle-close {
		color: #FFFFFF !important;
	}

	.content-box {
		padding: 10px 30px 0;

		.table-number {
			margin: 0;
			color: #3399FF;
			font-size: 13px;
			text-decoration: underline;
			padding: 0;
			cursor: pointer;
		}

		.pagination {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;
		}

		.seach-box {
			border-radius: 5px;
			border: 0.5px solid #CCCCCC;

			.form-box {
				padding: 17px 29px 13px 29px;
			}


		}
	}
</style>
