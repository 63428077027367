<template>
	<div>
		<!-- 违法车辆打印 -->
		<div id="printwf" ref="printwf" v-show="false">
			<div v-for="(item,index) in 2" :class="{'block':index!=0,'defaut':index==0}" style="position: relative;"
				:key="index">

				<h2 style="text-align: center;">彭州市国经交通公司涉案停车场·结案出场单</h2>
				<p style="text-align: right;padding-right: 22px;">编号：{{content.print_code}}</p>
				<table class="gridtable">

					<tr>
						<th>扣车时间</th>
						<td class="item_td">{{content.create_time ||""}}</td>
						<th>事故地点</th>
						<td class="item_td">{{content.address||''}}</td>
					</tr>
					<tr>
						<th>车辆类型</th>
						<td class="item_td">{{(content.model?content.model:'')}}
						</td>
						<th>车牌号码(车架号、发动机号)</th>
						<td class="item_td">
							{{content.car_no}}({{(content.frame_number?content.frame_number:"")+'/'+(content.engine_number?content.engine_number:'')}})
						</td>
					<tr>
					<tr>
						<th>扣车民警</th>
						<td class="item_td">{{content.police_name||""}}</td>
						<th>停车场接收人</th>
						<td class="item_td">{{content.parking_username||""}}</td>
					<tr>
					<tr>
						<th>出场时间</th>
						<td class="item_td">{{content.delivery_time}}</td>
						<th>超时停车费用(元)</th>
						<td class="item_td">{{content.amount+'元'||"无费用"}}</td>

					<tr>
					<tr>
						<th>施救人</th>
						<td class="item_td">{{content.work_name||""}}</td>
						<th>出场审核人</th>
						<td class="item_td">
							{{content.operator_name}}
						</td>
					</tr>
					 <!-- v-if="content.invoicing": -->
					 <tr>
						<th>车辆二维码</th>
						<td>
							<img :src="content.carqrcode"  width="50" height="50">
						</td>
						<th>签名（盖指纹）</th>
						<td class="item_td">(　　　　　　　　　　　　　　　)</td>
					</tr>
					<tr v-if="content.invoicing">
						<th>开票二维码</th>
						<td>
							<img :src="content.invoicing"  width="50" height="50">
						</td>
						<th>备注</th>
						<td ></td>
					</tr>
					 <!-- v-else -->
					<tr v-else>
						<th>备注</th>
						<td class="item_td" colspan="3"></td>
					</tr>
					
					<!-- <tr>
						<th>备注</th>
						<td class="item_td" colspan="3"></td>
					</tr> -->
				</table>
				<div class="bottom"><span>地址：{{content.parking_address}}</span>
					<span>电话:{{content.parking_phone}}</span>
				</div>
				<!-- <div v-if="!content.invoicing&&index==0" style="height: 10px;"></div> -->
			</div>

		</div>
	</div>
</template>

<script>
	import {
		print
	} from './js/paint.js';
	export default {
		name: 'Print',
		props: {},
		data() {
			return {
				content: {}, //打印的内容
			}
		},
		methods: {
			/**
			 * 开启系统打印控件
			 * @param {Object} content //打印的内容
			 */
			async beganPrint(id) {
				let res = await this.http.apiGet('v1/park/PrintComeOut', {
					id
				}, true);
				console.log(res);
				
				if (res.flag) {
					this.content = res.data;
					this.$nextTick(() => {
						setTimeout(()=>{
							let windows;
							windows = print(this.$refs.printwf.innerHTML, 1600, 800);
						},500)
						// if (content.case_type == 'type_accident') {
						// 	//事故车辆
						// 	windows = print(this.$refs.printfsg.innerHTML,1600,900);
						// } else if (content.case_type == 'type_case') {
						// 	//涉案车辆
						// 	windows = print(this.$refs.printsa.innerHTML,1600,900);
						// } else {
						// 	//违法车辆

						// }
					})
				} else {
					this.$message.warning(res.msg);
				}


			},
		},

	}
</script>

<style>
</style>
