import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import mixin from "./common/mixin.js"
import 'element-ui/lib/theme-chalk/index.css';
// import './theme/index.css'
import './theme/element-variables.scss'
import router from "./router"
import store from './store'
import http from "./api/http.js"
//注册导出表格
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
//注册打印
import Print from 'vue-print-nb'
Vue.use(Print); //注册
//全局定义一个eventBus
window.eventBus = new Vue();
Vue.prototype.http = http
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.mixin(mixin)
new Vue({
	// 注入到根实例中
	router: router.router,
	store,
	render: h => h(App)
}).$mount('#app')
